import { get, uniqBy } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { action, PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import { handleActions } from 'redux-actions';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { User } from '../declaration/user';
import { Profile } from '../declaration/profile';
import { Review } from '../declaration/review';
import { Envelope } from '../declaration';
import { MonthlyTrade } from '../declaration/monthly-trades';

export enum UserTypes {
  setToken = '@user/setToken',
  getUser = '@user/getUser',
  getUserProfile = '@user/getUserProfile',
  getUserReviews = '@user/getUserReviews',
  getUserMonthlyTrades = '@user/getUserMonthlyTrades',
  setOfficeInfoUpdateRequest = '@user/setOfficeInfoUpdateRequest'
}

export interface OfficeInfoUpdateData {
  is_complex_changed: boolean;
  complex?: number | null;
  is_office_changed: boolean;
  office?: string;
}

export interface UserState {
  token: string | null;
  userMap: { [key: string]: User };
  profileMap: { [key: string]: Profile };

  reviews: Array<Review>;
  hasNextReviews: boolean;
  totalReviewsCount: number | null;

  monthlyTrades: Array<MonthlyTrade>;
  isLoadedMonthlyTrades: boolean;
}

export const UserActions = {
  setToken: (token: string) => action(UserTypes.setToken, token),
  getUser: createAsyncSagaAction(UserTypes.getUser, (hashId: string) => {
    return axios.get(`/users/${hashId}/`);
  }),
  getUserProfile: createAsyncSagaAction(UserTypes.getUserProfile, (hashId: string) => {
    return axios.get(`/users/${hashId}/profile/`);
  }),
  getUserReviews: createAsyncSagaAction(UserTypes.getUserReviews, (hashId: string, page: number) => {
    const query = new URLSearchParams();
    query.set('page', page.toString());
    query.set('envelope', 'true');

    return axios.get(`/users/${hashId}/reviews/`, { params: query });
  }),
  getUserMonthlyTrades: createAsyncSagaAction(UserTypes.getUserMonthlyTrades, (hashId: string) => {
    return axios.get(`/users/${hashId}/monthly_trades/`);
  }),
  setOfficeInfoUpdateRequest: createAsyncSagaAction(
    UserTypes.setOfficeInfoUpdateRequest,
    (id: number, data: OfficeInfoUpdateData) => {
      return axios.patch(`/office_info_update_request/${id}/`, data);
    }
  )
};

const initialState: UserState = {
  token: null,
  reviews: [],
  hasNextReviews: true,
  totalReviewsCount: null,

  monthlyTrades: [],
  isLoadedMonthlyTrades: false,

  userMap: {},
  profileMap: {}
};

export default handleActions<UserState, any>(
  {
    [UserTypes.setToken]: (state, action: PayloadAction<string, string>) => {
      return produce(state, draft => {
        draft.token = action.payload;
      });
    },
    ...createAsyncSagaReducerMap(UserTypes.getUser, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<User>, [string]>) => {
        return produce(state, draft => {
          const hashId = action.meta[0];
          draft.userMap[hashId] = action.payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(UserTypes.getUserProfile, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<Profile>, [string]>) => {
        return produce(state, draft => {
          const hashId = action.meta[0];
          draft.profileMap[hashId] = action.payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(UserTypes.getUserReviews, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<Envelope<Review>>, [string]>) => {
        return produce(state, draft => {
          const { meta, results } = action.payload.data;
          const count = get(meta, 'count');
          const next = get(meta, 'next');
          draft.reviews = uniqBy([...state.reviews, ...results], 'hash_id');
          draft.hasNextReviews = next !== null;
          draft.totalReviewsCount = count;
        });
      }
    }),
    ...createAsyncSagaReducerMap(UserTypes.getUserMonthlyTrades, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<Array<MonthlyTrade>>, [string]>) => {
        return produce(state, draft => {
          draft.monthlyTrades = action.payload.data;
          draft.isLoadedMonthlyTrades = true;
        });
      }
    })
  },
  initialState
);
