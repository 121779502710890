export function getGradeDisplay(grade?: string) {
  switch (grade) {
    case 'L0':
    case 'L1':
      return '회원딜러';
    case 'L2':
      return '헤비딜러';
    default:
      return '-';
  }
}
