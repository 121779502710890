import React, { FC, useEffect, useState } from 'react';
import styles from './ImageLoader.module.scss';
import classNames from 'classnames';
import Loading from '../Loading/Loading';

interface Props {
  imageClassName?: string;
  className?: string;
  image: string;
}

const ImageLoader: FC<Props> = ({ className, image ,imageClassName}) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const img = new Image();
    img.src = image;
    img.addEventListener('load', () => {
      setLoading(false);
    });
  }, [image]);

  return (
    <div className={classNames(styles.imageLoader, className)}>
      <Loading className={classNames(styles.loading, !isLoading && styles.disabled)} />
      <div
        className={classNames(styles.image, imageClassName)}
        style={{ background: `url(${image}) no-repeat center`, backgroundSize: 'cover', opacity: isLoading ? 0 : 1 }}
      />
    </div>
  );
};

export default ImageLoader;
