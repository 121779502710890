import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './OfficeInfoUpdate.module.scss';
import CloseButton from '../CloseButton/CloseButton';
import Emoji from '../Emoji/Emoji';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { OfficeInfoUpdateData, UserActions } from '../../store/user';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, chain } from 'lodash';
import QuestionItem from './QuestionItem/QuestionItem';
import { ComplexActions } from '../../store/complex';
import Ink from 'react-ink';
import classNames from 'classnames';
import { useToast } from '../../hooks/useToast';
import RegisterSelect from '../RegisterSelect/RegisterSelect';

interface Props {}

const OfficeInfoUpdate: FC<Props> = memo(() => {
  const { hashId } = useParams<{ hashId: string }>();
  const [isComplexChanged, setComplexChanged] = useState<boolean | null>(null);
  const [isOfficeChanged, setOfficeChanged] = useState<boolean | null>(null);
  const [office, setOffice] = useState<string>('');
  const [locationFirstPart, setLocationFirstPart] = useState<number>(-1);
  const [locationSecondPart, setLocationSecondPart] = useState<number>(-1);
  const [complex, setComplex] = useState<number | null>(-1);
  const dispatch = useDispatch();
  const { user, locationFirstParts, locationSecondParts, complexes } = useTypedSelector(
    ({ user: { userMap }, complex: { locationFirstParts, locationSecondParts, complexes } }) => {
      return {
        user: hashId ? get(userMap, hashId) : null,
        locationFirstParts,
        locationSecondParts,
        complexes
      };
    }
  );

  const toast = useToast();

  const isActivateUpdateButton = useMemo(
    () => (isComplexChanged ? complex !== -1 : true) && (isOfficeChanged ? office.trim() !== '' : true),
    [isComplexChanged, isOfficeChanged, office, complex]
  );

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUser(hashId));
    dispatch(ComplexActions.getLocationFirstParts());
  }, [hashId]);

  useEffect(() => {
    if (locationFirstPart === -1) {
      return;
    }

    setComplex(-1);
    setLocationSecondPart(-1);
    dispatch(ComplexActions.truncateComplexes());
    dispatch(ComplexActions.truncateLocationSecondParts());
    dispatch(ComplexActions.getLocationSecondParts(locationFirstPart));
  }, [locationFirstPart]);

  useEffect(() => {
    if (locationSecondPart === -1) {
      return;
    }

    setComplex(-1);
    dispatch(ComplexActions.truncateComplexes());
    dispatch(ComplexActions.getComplexes(locationSecondPart));
  }, [locationSecondPart]);

  const officeInfoUpdateRequest = get(user, 'office_info_update_request');
  const id = get(officeInfoUpdateRequest, 'id');
  const isCompleted = get(officeInfoUpdateRequest, 'is_completed');

  const currentComplex = get(user, 'complex_display');
  const currentOffice = get(user, 'office');
  const fullName = get(user, 'full_name');

  const isEmptyComplexes = complexes !== null && chain(complexes).get('complexes').size().value() <= 0;

  return (
    <div className={styles.officeInfoUpdate}>
      <CloseButton className={styles.closeButton} />
      <div className={styles.header}>
        <h1 className={styles.title}>
          <div>{fullName}님,</div>
          <div>
            <span>소속 정보를 업데이트 해주세요!</span>
            <Emoji size={20} name="index-finger-pointing-up" />
          </div>
        </h1>
        <p className={styles.description}>포토그래퍼 방문 촬영에 필요한 정보이니, 정확한 확인 부탁드립니다!</p>
      </div>
      <QuestionItem
        index={1}
        title="소속 매매단지 정보가 맞으신가요?"
        currentValue={currentComplex}
        which={isComplexChanged}
        onChangeWhich={(which: boolean) => {
          setComplexChanged(which);
        }}
        falselyComponent={
          <div className={styles.selects}>
            <RegisterSelect
              variant="b"
              placeholder="시/도"
              value={locationFirstPart}
              options={chain(locationFirstParts)
                .map(locationFirstPart => ({
                  name: get(locationFirstPart, 'name'),
                  value: get(locationFirstPart, 'id')
                }))
                .value()}
              onChange={id => {
                setLocationFirstPart(id as number);
              }}
            />
            <RegisterSelect
              variant="b"
              placeholder="시/군/구"
              value={locationSecondPart}
              options={chain(locationSecondParts)
                .get('location_second_parts')
                .map(locationSecondPart => ({
                  name: get(locationSecondPart, 'name'),
                  value: get(locationSecondPart, 'id')
                }))
                .value()}
              onChange={id => {
                setLocationSecondPart(id as number);
              }}
            />
            <RegisterSelect
              variant="b"
              placeholder="매매단지"
              value={complex}
              options={isEmptyComplexes ? [{ name: '매매단지 없음', value: null }] : chain(complexes)
                .get('complexes')
                .map(complex => ({ name: get(complex, 'name'), value: get(complex, 'id') }))
                .value()}
              onChange={id => {
                setComplex(id as number);
              }}
            />
          </div>
        }
      />
      <QuestionItem
        index={2}
        title="근무 중인 상사이름이 맞으신가요?"
        currentValue={currentOffice}
        which={isOfficeChanged}
        onChangeWhich={(which: boolean) => {
          setOfficeChanged(which);
        }}
        falselyComponent={
          <div>
            <input
              className={styles.input}
              placeholder="현재 근무 중인 상사이름"
              value={office}
              onChange={e => {
                setOffice(e.currentTarget.value);
              }}
            />
          </div>
        }
      />
      <div className={styles.actions}>
        <button
          className={classNames(styles.updateButton, isActivateUpdateButton && styles.isActivated)}
          onClick={() => {
            if (!isActivateUpdateButton) {
              toast.error('모두 입력해주세요.');
              return;
            }

            const data: Partial<OfficeInfoUpdateData> = {};
            if (typeof isComplexChanged === 'boolean') {
              data.is_complex_changed = isComplexChanged;
            }

            if (isComplexChanged && complex !== -1) {
              data.complex = complex;
            }

            if (typeof isOfficeChanged === 'boolean') {
              data.is_office_changed = isOfficeChanged;
            }

            if (isOfficeChanged && office.trim() !== '') {
              data.office = office;
            }

            if (id !== undefined) {
              dispatch(UserActions.setOfficeInfoUpdateRequest(id, data as OfficeInfoUpdateData));
            }
          }}
        >
          업데이트
          <Ink />
        </button>
      </div>
      {isCompleted && (
        <div className={styles.blind}>
          <span>이미 입력이 완료되었습니다.</span>
        </div>
      )}
    </div>
  );
});

export default OfficeInfoUpdate;
