import React, { FC } from 'react';
import styles from './StudyVideo.module.scss';
import MoviePlayer from '../MoviePlayer/MoviePlayer';

interface Props {
  hashId: string;
  url: string;
  isCompleted: boolean;
  onComplete: (isCompleted: boolean) => void;
}

const StudyVideo: FC<Props> = ({ hashId, url, isCompleted, onComplete }) => {
  return (
    <div className={styles.studyVideo}>
      <div className={styles.player}>
        <MoviePlayer hashId={hashId} url={url} isCompleted={isCompleted} onComplete={onComplete} />
      </div>
      <div className={styles.additional}>
        <p>
          <span>*</span>
          <span>
            영상 전체를 시청하셔야, 다음 단계로 넘어갈 수 있습니다. <br />
            (영상 빨리보기나 이동 불가)
          </span>
        </p>
        <p>
          <span>*</span>
          <span>마지막 ‘퀴즈풀기’ 단계에서 문제가 출제됩니다!</span>
        </p>
      </div>
    </div>
  );
};

export default StudyVideo;
