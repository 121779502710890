import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import RecentReviews from 'src/components/RecentReviews/RecentReviews';

import Review from 'src/components/ReviewPage/ReviewPage';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { UserActions } from 'src/store/user';

const ReviewPage: FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const dispatch = useDispatch();

  const { profile } = useTypedSelector(({ user: { profileMap } }) => {
    return { profile: hashId ? profileMap[hashId] || null : null };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserProfile(hashId));
  }, [dispatch, hashId]);

  return (
    <div>
      <Review reviews={profile?.reviews} tradedCarsCount={profile?.trades.traded_cars_count} />
      <RecentReviews />
    </div>
  );
};

export default ReviewPage;
