import { get } from 'lodash';
import React, { FC } from 'react';
import styles from './RecentReviewsItem.module.scss';
import { Review } from '../../declaration/review';
import { getResizeUrl } from '../../cores/getResizeUrl';
import RecentReviewTradeResult from './RecentReviewTradeResult/RecentReviewTradeResult';
import StarRating from '../StarRating/StarRating';

interface Props {
  data: Review;
}

const RecentReviewsItem: FC<Props> = ({ data }) => {
  const car = get(data, 'car');
  const content = get(data, 'content');
  const rating = get(data, 'rating');
  const trade = get(car, 'trade');
  const etc = get(car, 'etc');
  const abTest = get(etc, 'ab_test');
  const inputtedAtDisplay = get(data, 'inputted_at_display');
  const user = get(car, 'user');
  const userName = get(user, 'user_name');
  const imageUrl = get(data, 'image_url');
  const detail = get(car, 'detail');
  const auction = get(car, 'auction');
  const mainImageUrl = get(detail, 'main_image_url');
  const selectedBid = get(auction, 'selected_bid');
  const selectedBidPrice = get(selectedBid, 'price');
  const carNumber = get(detail, 'car_number');

  const resizeImageUrl = imageUrl ? getResizeUrl(500, 300, imageUrl) : null;

  return (
    <div className={styles.recentReviewsItem}>
      <div className={styles.left}>
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: mainImageUrl ? `url(${getResizeUrl(100, 100, mainImageUrl)})` : '' }}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.review}>
          <h1 className={styles.title}>{userName}</h1>
          <div className={styles.rating}>
            <StarRating rating={rating} />
          </div>
          <div className={styles.content}>{content}</div>
          {resizeImageUrl && <div className={styles.image} style={{ backgroundImage: `url(${resizeImageUrl})` }} />}
          <div className={styles.inputtedAtDisplay}>
            {inputtedAtDisplay}
          </div>
        </div>
        <RecentReviewTradeResult carNumber={carNumber} abTest={abTest} tradeResult={trade} selectedBidPrice={selectedBidPrice} />
      </div>
    </div>
  );
};

export default RecentReviewsItem;
