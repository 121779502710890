import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Layer.module.scss';
import { Portal } from 'react-portal';
import { animated, useSpring } from 'react-spring';
import { useHistory, useLocation } from 'react-router';
import classNames from 'classnames';
import { confirmCloseWebview } from '../../cores/confirmCloseWebview';

interface Props {
  id?: string;
  value: string;
  white?: boolean;
  overview?: boolean;
  closeButton?: boolean;
}

const Layer: FC<Props> = ({ id, value, children, white, overview, closeButton }) => {
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const layers = query.getAll('layer');
  const visible = layers.indexOf(value) !== -1;
  const isDirect = query.get('direct') === 'true';
  const [displayed, setDisplayed] = useState(visible);

  const isEnableFullScreen = query.get('is_enable_full_screen') === 'true';

  const onRest = useCallback(() => {
    if (!visible) {
      setDisplayed(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible && !displayed) {
      setDisplayed(true);
    }
  }, [displayed, visible]);

  const spring = useSpring({ x: visible ? 0 : 100, config: { mass: 1, tension: 300, friction: 35 }, onRest });

  if (!displayed) {
    return null;
  }

  return (
    <Portal>
      <animated.div
        id={id}
        className={classNames(
          styles.layer,
          white && styles.white,
          overview && styles.overview,
          closeButton && styles.closeButton
        )}
        style={{ [closeButton ? 'top' : 'left']: spring.x.interpolate((x) => `${x}%`) }}
      >
        <Portal>
          <animated.div
            className={classNames(
              styles.header,
              white && styles.white,
              overview && styles.overview,
              closeButton && styles.closeButton,
              isEnableFullScreen && styles.isEnableFullScreen
            )}
            style={{ [closeButton ? 'top' : 'left']: spring.x.interpolate((x) => `${x}%`) }}
          >
            <button
              className={styles.back}
              onClick={() => {
                if (isDirect) {
                  if (isEnableFullScreen) {
                    history.goBack();
                  } else {
                    confirmCloseWebview();
                  }
                } else {
                  history.goBack();
                }
              }}
            />
          </animated.div>
        </Portal>
        <div className={styles.content}>{children}</div>
      </animated.div>
    </Portal>
  );
};

export default Layer;
