import { get } from 'lodash';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import styles from './Profile.module.scss';
import classNames from 'classnames';
import ReviewPanel from '../ReviewPanel/ReviewPanel';
import SidePanel from '../SidePanel/SidePanel';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/user';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getResizeUrl } from '../../cores/getResizeUrl';
import { getGradeDisplay } from '../../cores/getGradeDisplay';
import Layer from '../Layer/Layer';
import ReviewPage from '../ReviewPage/ReviewPage';
import RecentReviews from '../RecentReviews/RecentReviews';
import SatisfactionPage from '../SatisfactionPage/SatisfactionPage';
import WarningsPage from '../WarningsPage/WarningsPage';
import NumberEasing from '../NumberEasing/NumberEasing';
import TradedCarsPage from '../TradedCarsPage/TradedCarsPage';
import MonthlyTrades from '../MonthlyTrades/MonthlyTrades';
import SuccessRatePage from '../SuccessRatePage/SuccessRatePage';
import PreviewCars from '../PreviewCars/PreviewCars';
import Cars from '../Cars/Cars';
import ProfileDetail from '../ProfileDetail/ProfileDetail';
import Helmet from 'react-helmet';
import CloseButton from '../CloseButton/CloseButton';
import WarningAndFee from '../WarningAndFee/WarningAndFee';
import Cookies from 'js-cookie';
import { compare } from 'compare-versions';
import Reschedule from 'src/pages/Reschedule/Reschedule';

interface Props {}

const Profile: FC<Props> = memo(() => {
  const { hashId } = useParams<{ hashId: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const dispatch = useDispatch();

  const { profile } = useTypedSelector(({ user: { profileMap } }) => {
    return {
      profile: hashId ? get(profileMap, hashId) || null : null,
    };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserProfile(hashId));
  }, [dispatch, hashId]);

  const onOpenLayer = useCallback(
    (value: string) => {
      return () => {
        const layers = query.getAll('layer');
        if (layers.indexOf(value) === -1) {
          query.delete('layer');
          layers.push(value);

          for (let i = 0, length = layers.length; i < length; i++) {
            query.append('layer', layers[i]);
          }

          history.push(`?${query.toString()}`);
        }
      };
    },
    [query]
  );

  const reviews = get(profile, 'reviews');
  const basicInformation = get(profile, 'basic_information');
  const failedReview = get(profile, 'failed_review');
  const trades = get(profile, 'trades');

  const profileImageUrl = get(basicInformation, 'profile_image_url');
  const fullName = get(basicInformation, 'full_name');
  const grade = get(basicInformation, 'grade');
  const gradeDisplay = getGradeDisplay(grade);

  const count = get(failedReview, 'count') || 0;
  const countByChoice = get(failedReview, 'count_by_choice');
  const fair = get(countByChoice, 'fair');
  const poor = get(countByChoice, 'poor');
  const reviewsCount = get(reviews, 'reviews_count');
  const reviewsRate = get(reviews, 'review_rate');
  const averageRating = get(reviews, 'average_rating');

  const good = get(countByChoice, 'good') || 0;
  const successRate = get(trades, 'success_rate');
  const warnings = profile?.warnings;
  const warningsCount = get(warnings, 'warnings_count');
  const mistakesCount = get(warnings, 'mistakes_count');
  const monthlyTradedCars = get(trades, 'monthly_traded_cars');
  const selectedCarsCount = get(trades, 'selected_cars_count');
  const tradedCarsCount = get(trades, 'traded_cars_count');
  const recent100TradedCarsCount = get(trades, 'recent_100_traded_cars_count');
  const recent100FailedCarsCount = get(trades, 'recent_100_failed_cars_count');
  const averageSuccessRate = get(trades, 'average_success_rate');

  const isHeavyDealer = grade === 'L2';

  const successRateValues = useMemo(() => {
    return [
      {
        value: successRate || 0,
        color: '#396eff',
      },
      {
        value: 100 - (successRate || 100),
        color: '#e9edf4',
      },
    ];
  }, [successRate]);

  const satisfactionPercent = useMemo(() => {
    return (good / count) * 100;
  }, [good, count]);

  const satisfactionValues = useMemo(() => {
    return [
      {
        value: good,
        color: '#396eff',
      },
      {
        value: fair,
        color: '#ccd8e8',
      },
      {
        value: poor,
        color: '#ed7583',
      },
    ];
  }, [good, fair, poor]);

  const getTrimmedAppVersion = (appVersion: string) => /\d+\.\d+\.\d+/.exec(appVersion)?.[0] || '';

  const appOs = Cookies.get('App-Os');
  const appVersion = Cookies.get('App-Version');

  return (
    <div className={classNames(styles.profile, isHeavyDealer && styles.heavyDealer)}>
      <Helmet>
        <title>딜러 프로필 | 헤이딜러</title>
      </Helmet>
      <div className={styles.header}>
        <div className={styles.text}>
          <h1 className={styles.name}>{fullName || '-'}</h1>
          <p className={styles.grade}>{gradeDisplay || '-'}</p>
        </div>
        <div
          className={styles.thumbnail}
          style={{ background: profileImageUrl && `url(${getResizeUrl(100, 100, profileImageUrl)})` }}
          onClick={onOpenLayer('profile-detail')}
        >
          <div className={styles.icon} />
        </div>
      </div>
      <div className={styles.panel}>
        <div className={styles.row}>
          <div className={styles.column1}>
            <ReviewPanel
              reviewsCount={reviewsCount}
              reviewsRate={reviewsRate}
              rating={averageRating}
              onClick={onOpenLayer('reviews')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column2}>
            <SidePanel
              title="선택 대비 거래"
              subtitle="거래 성사율"
              percent={successRate}
              values={successRateValues}
              onClick={onOpenLayer('success-rate')}
            />
          </div>
          <div className={styles.column2}>
            <SidePanel
              title="거래취소 고객"
              subtitle="상담 만족도"
              percent={satisfactionPercent}
              values={satisfactionValues}
              onClick={onOpenLayer('satisfaction')}
            />
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={classNames(styles.item, styles.highlight)} onClick={onOpenLayer('monthly-trades')}>
          <span className={styles.left}>{new Date().getMonth() + 1}월 거래완료</span>
          <span className={styles.right}>
            <NumberEasing value={(monthlyTradedCars?.basic_count || 0) + (monthlyTradedCars?.zero_count || 0)} />대
          </span>
        </div>
        <div className={classNames(styles.item, styles.highlight)} onClick={onOpenLayer('reschedule')}>
          <span className={styles.left}>재매입 신고</span>
        </div>
        <div className={styles.item} onClick={onOpenLayer('warnings')}>
          <span className={styles.left}>경고</span>
          <span className={styles.right}>
            경고 <NumberEasing value={warningsCount} /> · 견적실수 <NumberEasing value={mistakesCount} />
          </span>
        </div>
        <div
          className={styles.item}
          onClick={() =>
            (window.location.href = `heydealerfordealer://webview?url=${window.location.protocol}%2F%2F${window.location.host}%2Fstudy%2F${hashId}%2F%3Ftoolbar%3Dfalse`)
          }
        >
          <span className={styles.left}>딜러 스터디</span>
        </div>
        <div className={styles.item} onClick={() => (window.location.href = 'heydealerfordealer://chat')}>
          <span className={styles.left}>채팅문의</span>
        </div>
        {appOs === 'ios' && appVersion && compare(getTrimmedAppVersion(appVersion), '3.3.0', '>=') && (
          <div className={styles.item} onClick={() => (window.location.href = 'heydealerfordealer://deleteAccount')}>
            <span className={styles.left}>회원딜러 탈퇴 신청</span>
          </div>
        )}
      </div>
      <Layer value="reschedule">
        <Reschedule />
      </Layer>
      <Layer id="review-layer" value="reviews">
        <ReviewPage reviews={reviews} tradedCarsCount={tradedCarsCount} />
        <RecentReviews />
      </Layer>
      <Layer value="success-rate">
        <SuccessRatePage
          successRate={successRate}
          averageSuccessRate={averageSuccessRate}
          selectedCarsCount={selectedCarsCount}
          recent100TradedCarsCount={recent100TradedCarsCount}
          recent100FailedCarsCount={recent100FailedCarsCount}
        />
        <PreviewCars hashId={hashId} />
      </Layer>
      <Layer value="satisfaction">
        <SatisfactionPage failedReview={failedReview} />
      </Layer>
      <Layer id="monthly-trades-layer" value="monthly-trades">
        <TradedCarsPage monthlyTradedCars={monthlyTradedCars} onOpenWarningAndFee={onOpenLayer('warning-and-fee')} />
        <MonthlyTrades />
      </Layer>
      <Layer value="warnings">
        <WarningsPage warnings={warnings} onOpenWarningAndFee={onOpenLayer('warning-and-fee')} />
      </Layer>
      <Layer value="profile-detail" white overview>
        {profile && <ProfileDetail data={profile} />}
      </Layer>
      <Layer id="cars-layer" value="cars">
        <Cars />
      </Layer>
      <Layer value="warning-and-fee" closeButton>
        <WarningAndFee />
      </Layer>
    </div>
  );
});

export default Profile;
