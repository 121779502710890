import React, { FC } from 'react';
import styles from './CarsItem.module.scss';
import { Cars } from '../../declaration/cars';
import { get } from 'lodash';
import { getResizeUrl } from '../../cores/getResizeUrl';
import { toLocaleString } from '../../cores/toLocaleString';
import StarRating from 'react-svg-star-rating';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useToast } from '../../hooks/useToast';

interface Props {
  data: Cars;
}

const CarsItem: FC<Props> = ({ data }) => {
  const standardWidth = window.innerWidth - 40;
  const width = standardWidth * 0.475;
  const height = width * 1.157894737;
  const space = standardWidth * 0.05;
  const thumbnailSize = width * 0.236842105;

  const fontSize10 = height * 0.056818182;
  const fontSize12 = height * 0.068181818;
  const marginBottom4 = width * 0.02631579;
  const marginBottom8 = marginBottom4 * 2;
  const marginBottom16 = marginBottom8 * 2;

  const toast = useToast();

  const detail = get(data, 'detail');
  const auction = get(data, 'auction');
  const status = get(data, 'status');
  const statusDisplay = get(data, 'status_display');
  const selectedBid = get(auction, 'selected_bid');
  const selectedAt = get(auction, 'selected_at');
  const price = get(selectedBid, 'price');
  const carNumber = get(detail, 'car_number');
  const mainImageUrl = get(detail, 'main_image_url');
  const selectedAtDisplay = DateTime.fromFormat(selectedAt, 'yyyy-MM-dd HH:mm:ss').toFormat('yy.MM.dd');
  const trade = get(data, 'trade');
  const rating = get(trade, ['review', 'rating']);
  const isCompleted = status === 'completed';
  return (
    <div
      className={styles.carsItem}
      style={{ width, height, marginBottom: space, padding: width * 0.105263158 }}
      onClick={() => {
        toast.info('차량 상세내용은 딜러PC사이트에서 확인해주세요!');
      }}
    >
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{ width: thumbnailSize, height: thumbnailSize, background: `url(${getResizeUrl(200, 200, mainImageUrl)})` }}
        />
        <div className={styles.information}>
          <div>
            <h1 className={styles.carNumber} style={{ fontSize: fontSize12, marginBottom: marginBottom16 }}>
              {carNumber}
            </h1>
            <p
              className={styles.price}
              style={{ fontSize: fontSize10, marginBottom: isCompleted ? marginBottom4 : marginBottom8 }}
            >
              {toLocaleString(price)}만원
            </p>
            {isCompleted && rating ? (
              <StarRating
                unit="float"
                initialRating={rating / 2}
                isReadOnly
                activeColor="#396eff"
                size={fontSize10}
                roundedCorner={false}
                starClassName={styles.star}
                containerClassName={styles.startContainer}
              />
            ) : (
              <p style={{ fontSize: fontSize10 }}>{selectedAtDisplay} 선택</p>
            )}
          </div>

          <div>
            <h2 className={classNames(styles.statusDisplay, get(styles, status))} style={{ fontSize: fontSize12 }}>
              {statusDisplay}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarsItem;
