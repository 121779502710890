import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './MoviePlayer.module.scss';
import Loading from '../Loading/Loading';
import { Portal } from 'react-portal';
import Player from '@vimeo/player';
import { toTime } from '../../cores/toTime';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

interface Props {
  hashId: string;
  url: string;
  isCompleted: boolean;
  onComplete: (isCompleted: boolean) => void;
}

const MoviePlayer: FC<Props> = ({ hashId, url, isCompleted, onComplete }) => {
  const key = useMemo(() => {
    return hashId;
  }, [hashId]);

  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const frameRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const isCompletedRef = useRef(localStorage.getItem(`${key}:isCompleted`) === 'true');
  const isPressed = useRef<boolean>(false);
  const mouseDownX = useRef<number>(0);
  const startedAt = useMemo(() => {
    return new Date().getTime();
  }, []);

  const localWatchingTime = useMemo(() => {
    const watchingTime = localStorage.getItem(`${key}:watchingTime`);

    if (watchingTime === null) {
      return 0;
    }

    return Number(watchingTime);
  }, [key]);

  const [targetPositionX, setTargetPositionX] = useState(0);

  const onMouseDown = useCallback(
    e => {
      if (!isCompleted) {
        return;
      }

      isPressed.current = true;
    },
    [isCompleted]
  );

  const onTouchStart = useCallback(
    e => {
      if (!isCompleted) {
        return;
      }

      if (e.touches.length > 0) {
        isPressed.current = true;
      }
    },
    [isCompleted]
  );

  const isEnableFullScreen = query.get('is_enable_full_screen') === 'true';

  const [isEnded, setEnded] = useState(false);
  const [isVideoEnded, setVideoEnded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isPlaying, setPlaying] = useState(false);
  const [isBuffering, setBuffering] = useState(false);
  const [isFirstPlay, setFirstPlay] = useState(true);
  const [isVisibleOverview, setVisibleOverview] = useState(true);
  const [duration, setDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState(localWatchingTime);
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerWidth });
  const [watchingTime, setWatchingTime] = useState(localWatchingTime);

  useEffect(() => {
    if (isLoading || currentTime === null || duration === null || isPressed.current) {
      return;
    }

    setTargetPositionX((currentTime / duration) * window.innerWidth);
  }, [isLoading, currentTime, duration]);

  const setFullScreen = useCallback(
    (isEnable: boolean) => {
      if (isEnable) {
        query.set('is_enable_full_screen', 'true');
        history.push(`?${query.toString()}`);
      }
    },
    [query]
  );

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (isPressed.current) {
        const x = e.clientX;
        mouseDownX.current = Math.min(Math.max(x, 0), window.innerWidth);
        setTargetPositionX(mouseDownX.current);
      }
    };

    const onTouchMove = (e: TouchEvent) => {
      if (isPressed.current && e.touches.length > 0) {
        const x = e.touches[0].clientX;
        mouseDownX.current = Math.min(Math.max(x, 0), window.innerWidth);
        setTargetPositionX(mouseDownX.current);
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (isPressed.current) {
        const x = e.clientX - mouseDownX.current;
        isPressed.current = false;
      }
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (isPressed.current) {
        (async () => {
          if (!playerRef.current) {
            return;
          }

          await playerRef.current.pause();
          const duration = await playerRef.current.getDuration();
          await playerRef.current.setCurrentTime((mouseDownX.current / window.innerWidth) * duration);
          await playerRef.current.play();
          isPressed.current = false;
        })();
      }
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('touchcancel', onTouchEnd);
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mouseleave', onMouseUp);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
      window.removeEventListener('touchcancel', onTouchEnd);
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mouseleave', onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (!frameRef.current) {
      return;
    }

    playerRef.current = new Player(frameRef.current, {
      url,
      width: screenSize.width,
      height: screenSize.height,
      loop: false,
      autoplay: false,
      controls: false,
      playsinline: true,
      byline: false,
      muted: false,
      title: false,
      speed: true,
      transparent: false
    });

    const onBufferStart = () => {
      setBuffering(true);
    };

    const onBufferEnd = () => {
      setBuffering(false);
    };

    playerRef.current.on('bufferstart', onBufferStart);
    playerRef.current.on('bufferend', onBufferEnd);

    return () => {
      if (!playerRef.current) {
        return;
      }

      playerRef.current.off('bufferstart', onBufferStart);
      playerRef.current.off('bufferend', onBufferEnd);
    };
  }, [url]);

  useEffect(() => {
    if (!playerRef.current || !frameRef.current || isLoading) {
      return;
    }

    const iframeNodes = frameRef.current.getElementsByTagName('iframe');

    if (iframeNodes.length > 0) {
      iframeNodes[0].setAttribute('width', screenSize.width.toString());
      iframeNodes[0].setAttribute('height', screenSize.height.toString());
    }
  }, [screenSize, isLoading]);

  useEffect(() => {
    (async () => {
      if (!playerRef.current) {
        return;
      }

      try {
        await playerRef.current.ready();
        const duration = await playerRef.current.getDuration();

        setDuration(duration);
        setLoading(false);
      } catch (e) {
        alert(e);
      }
    })();
  }, []);

  useEffect(() => {
    let nextWatchingTime = watchingTime;
    if (duration !== null && watchingTime > duration) {
      setWatchingTime(0);
    }
    localStorage.setItem(`${key}:watchingTime`, nextWatchingTime.toString());

    if (duration !== null) {
      const updatedAt = new Date().getTime();
      const diff = (updatedAt - startedAt) / 1000;
      const nextIsEnded = duration - localWatchingTime <= diff;
      setEnded(nextIsEnded);
      setVideoEnded(duration - currentTime <= 0.5);

      if (nextIsEnded) {
        localStorage.setItem(`${key}:isCompleted`, 'true');
        isCompletedRef.current = localStorage.getItem(`${key}:isCompleted`) === 'true';
      }
    }
  }, [watchingTime, currentTime, startedAt, duration, localWatchingTime, key]);

  useEffect(() => {
    onComplete(isEnded || isCompleted || isCompletedRef.current);
  }, [isEnded, isCompleted, currentTime]);

  useEffect(() => {
    let startedAt = new Date().getTime();

    function update() {
      const updatedAt = new Date().getTime();
      const delta = updatedAt - startedAt;
      startedAt = updatedAt;

      (async () => {
        if (!playerRef.current) {
          return;
        }

        const isPaused = await playerRef.current.getPaused();

        setPlaying(!isPaused);

        if (!isPaused) {
          setWatchingTime(prevWatchingTime => prevWatchingTime + (delta / 1000));
          setCurrentTime(await playerRef.current.getCurrentTime());
        }
      })();
    }

    const interval = setInterval(update, 100);

    return () => {
      clearInterval(interval);

      if (!playerRef.current) {
        return;
      }

      playerRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (isVideoEnded) {
      if (isEnableFullScreen) {
        history.goBack();
      }

      (async () => {
        if (!playerRef.current) {
          return;
        }

        await playerRef.current.pause();
        setVisibleOverview(true);
      })();
    }
  }, [isVideoEnded, setFullScreen, isEnableFullScreen, history]);

  useEffect(() => {
    if (isEnableFullScreen) {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    } else {
      setScreenSize({ width: window.innerWidth, height: window.innerWidth });
    }
  }, [isEnableFullScreen]);

  const cursorSpring = useSpring({
    x: targetPositionX,
    config: {
      mass: 1,
      tension: 500,
      friction: 30
    }
  }) as any;

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
  }, [isPlaying, currentTime]);

  return (
    <div className={classNames(styles.moviePlayer, isEnableFullScreen && styles.isEnableFullScreen)}>
      <div ref={frameRef} className={styles.frame} style={{ width: screenSize.width, height: screenSize.height }} />
      <div className={styles.overview}>
        <OutsideClickHandler
          display="inline"
          onOutsideClick={() => {
            if (isPlaying) {
              setVisibleOverview(false);
            }
          }}
        >
          <div
            className={classNames(styles.interface, isVisibleOverview && styles.isVisible)}
            onClick={() => {
              if (isPlaying) {
                setVisibleOverview(prevState => !prevState);
              }
            }}
          >
            <div className={styles.centerInterface}>
              {isLoading || isBuffering ? (
                <Loading className={styles.loading} />
              ) : isPlaying ? (
                <button
                  className={styles.pauseButton}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (!playerRef.current) {
                      return;
                    }

                    setVisibleOverview(true);
                    playerRef.current.pause();
                  }}
                />
              ) : (
                <button
                  className={styles.playButton}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();

                    (async () => {
                      if (!playerRef.current) {
                        return;
                      }

                      try {
                        if (isVideoEnded) {
                          playerRef.current.setCurrentTime(0);
                        } else if (isFirstPlay) {
                          playerRef.current.setCurrentTime(localWatchingTime);
                          setFirstPlay(false);
                        }
                        await playerRef.current.play();
                        setVisibleOverview(false);
                      } catch (e) {
                        alert(e);
                      }
                    })();
                  }}
                />
              )}
            </div>
            <div className={styles.bottomInterface}>
              <div>
                <span>{toTime(currentTime)}</span>
              </div>
              <div>
                <span>{typeof duration === 'number' ? toTime(duration) : '-'}</span>
                <button
                  className={styles.fullScreen}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const nextIsEnableFullScreen = !isEnableFullScreen;
                    if (nextIsEnableFullScreen) {
                      if (isVideoEnded) {
                        return;
                      }

                      setFullScreen(nextIsEnableFullScreen);
                    } else {
                      history.goBack();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
      <div className={styles.progressive}>
        <animated.div className={styles.progress} style={{ width: cursorSpring.x.interpolate((x: number) => x) }} />
        <animated.div
          className={styles.cursor}
          style={{ transform: cursorSpring.x.interpolate((x: number) => `translateX(${x - 8}px)`) }}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
        />
      </div>
    </div>
  );
};

export default MoviePlayer;
