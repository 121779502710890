import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import Warnings from 'src/components/WarningsPage/WarningsPage';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { UserActions } from 'src/store/user';

const WarningsPage: FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const dispatch = useDispatch();

  const { profile } = useTypedSelector(({ user: { profileMap } }) => {
    return { profile: hashId ? profileMap[hashId] || null : null };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserProfile(hashId));
  }, [dispatch, hashId]);

  return (
    <Warnings
      warnings={profile?.warnings}
      onOpenWarningAndFee={() =>
        (window.location.href =
          'heydealerfordealer://webview?url=https%3A%2F%2Fapi.heydealer.com%2Fposts%2FGB5Rz45E%2F&toolbar=true')
      }
    />
  );
};

export default WarningsPage;
