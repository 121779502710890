import React, { FC, memo, useEffect, useRef } from 'react';
import styles from './SidePanel.module.scss';
import { easeCubicInOut } from 'd3-ease';
import { sumBy } from 'lodash';
import NumberEasing from '../NumberEasing/NumberEasing';

interface Props {
  title: string;
  subtitle: string;
  percent?: number;
  values: Array<{ color: string; value?: number; }>;
  onClick: () => void;
}

const width = 40 * 2;
const height = 40 * 2;
const halfWidth = width * 0.5;
const halfHeight = height * 0.5;

const PI = Math.PI;
const PIHalf = PI * 0.5;
const PI2 = PI * 2;

const SidePanel: FC<Props> = memo(({ title, subtitle, percent, values, onClick }) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let cancelRequestAnimationId: number | null = null;
    let startedAt = Date.now();
    let delta = 0;
    let animation = 0;

    const total = sumBy(values, 'value');
    const context = ref.current.getContext('2d')!;

    const render = () => {
      cancelRequestAnimationId = window.requestAnimationFrame(render);

      const currentAt = Date.now();
      if (currentAt - startedAt > 0) {
        delta = (currentAt - startedAt) * 0.001;
        startedAt = currentAt;
      }

      animation += delta;

      if (animation > 1) {
        animation = 1;
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }

      const easing = easeCubicInOut(animation);

      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.strokeStyle = '#e9edf4';
      context.lineWidth = 8;
      context.arc(halfWidth, halfHeight, 36, 0, PI2);
      context.stroke();

      let prevStartAngle = 0;

      for (let i = 0; i < values.length; i++) {
        const { value, color } = values[i];

        if (typeof value !== 'number') {
          continue;
        }

        const ratio = value / total;
        const endAngle = PI + PIHalf ;
        const startAngle = PI + PIHalf - PI2 * ratio * easing;

        context.beginPath();
        context.strokeStyle = color;
        context.lineWidth = 8;
        context.arc(halfWidth, halfHeight, 36, startAngle - prevStartAngle, endAngle - prevStartAngle);
        context.stroke();

        context.beginPath();
        context.strokeStyle = '#fff';
        context.lineWidth = 8;
        context.arc(halfWidth, halfHeight, 36, startAngle - prevStartAngle, startAngle - prevStartAngle + PI * 0.02);
        context.stroke();

        prevStartAngle += PI2 * ratio * easing;
      }
    };

    cancelRequestAnimationId = window.requestAnimationFrame(render);

    return () => {
      if (cancelRequestAnimationId !== null) {
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }
    };
  }, [values]);

  const isPending = percent === undefined || isNaN(percent);

  return (
    <div className={styles.sidePanel} onClick={onClick}>
      <h1 className={styles.title}>
        <span>{title}</span>
        <span>&gt;</span>
      </h1>
      <div className={styles.content}>
        <p className={styles.subtitle}>{subtitle}</p>
        <p className={styles.percent}><NumberEasing value={percent} />{!isPending && <span style={{ fontSize: 12 }}>%</span>}</p>
      </div>
      <canvas className={styles.canvas} ref={ref} width={width} height={height} />
    </div>
  );
});

export default SidePanel;
