import React, { useEffect, useMemo } from 'react';
import styles from './Tracking.module.scss';
import { useDispatch } from 'react-redux';
import { TrackingActions } from '../../store/tracking';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getResizeUrl } from '../../cores/getResizeUrl';
import ImageLoader from '../ImageLoader/ImageLoader';
import TrackingReportForm from '../TrackingReportForm/TrackingReportForm';

const Tracking: React.FC<{}> = () => {
  const match = useRouteMatch()
  const trackingId = get(match, ['params', 'trackingId'])
  const { trackingResult } = useTypedSelector(({ tracking: { trackingResult } }) => {
    return {
      trackingResult
    };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if(trackingId) {
      dispatch(TrackingActions.getTrackingResult(parseInt(trackingId)));
    }
  }, [])

  if(!trackingResult) {
    return null;
  }

  const carNumber = get(trackingResult, ['car', 'detail', 'car_number'])
  const fullName = get(trackingResult, ['car', 'detail', 'full_name'])
  const mainImageUrl = get(trackingResult, ['car', 'detail', 'main_image_url'])
  const shortLocation = get(trackingResult, ['car', 'detail', 'short_location'])



  return (
    <div className={styles.tracking}>
      <div className={styles.content}>
        <div className={styles.title}>
          아래 차량을 매입한게 <br/>
          맞으신가요?
        </div>
        <div className={styles.car}>
          <div className={styles.thumbnail}>
            <ImageLoader className={styles.backgroundImage} imageClassName={styles.image} image={getResizeUrl(200, 200, mainImageUrl)} />
          </div>
          <div className={styles.carInfo}>
            <div className={styles.carName}>
              <span className={styles.carNumber}>[{carNumber}]</span>{fullName}
            </div>
            <div className={styles.location}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" className={styles.icon}>
              <g fill="none" fill-rule="evenodd">
                <g transform="translate(-140 -228)">
                  <path fill="#FFF" d="M0 0H360V720H0z"/>
                  <path fill="#414D6B" d="M0 0H360V292H0z"/>
                  <rect width="320" height="120" x="20" y="144" fill="#FFF" rx="4"/>
                  <path fill="#CCD8E8" d="M6.001 6.194c-2.14-.091-2.14-3.257 0-3.35 2.142-.09 2.134 3.259 0 3.35M6.168.761C6.116.752 6.061.749 6.001.75c-.059-.002-.114.001-.167.01C3.697.924 2.051 2.74 2.27 4.93c.224 2.245 1.85 3.913 3.182 5.584.25.313.846.297 1.099 0 1.39-1.637 2.97-3.269 3.181-5.532C9.938 2.773 8.351.925 6.168.761" transform="translate(140 228)"/>
                </g>
              </g>
            </svg>
              {shortLocation}</div>
          </div>
        </div>
      </div>
      <TrackingReportForm trackingId={trackingId}/>
    </div>
  );
}

export default Tracking;
