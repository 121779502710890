import { get } from 'lodash';
import React, { FC, memo } from 'react';
import styles from './ReviewPage.module.scss';
import LayerTitle from '../LayerTitle/LayerTitle';
import { Profile } from '../../declaration/profile';
import NumberEasing from '../NumberEasing/NumberEasing';
import CountByRating from '../CountByRating/CountByRating';
import RatingChart from '../RatingChart/RatingChart';

interface Props {
  tradedCarsCount?: number;
  reviews?: Profile['reviews'];
}

const Column: FC<{ value?: number; suffix: string; label: string; fractionDigits?: number }> = ({
  value,
  suffix,
  label,
  fractionDigits = 0,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.value}>
        <span>{typeof value === 'number' ? <NumberEasing value={value} fractionDigits={fractionDigits} /> : '-'}</span>
        <span className={styles.suffix}>{suffix}</span>
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

const ReviewPage: FC<Props> = memo(({ tradedCarsCount, reviews }) => {
  const reviewsCount = get(reviews, 'reviews_count');
  const reviewRate = get(reviews, 'review_rate');
  const averageRating = get(reviews, 'average_rating');
  const countByRating = get(reviews, 'count_by_rating');
  const averageReviewRate = get(reviews, 'average_review_rate');
  const totalAverageRating = get(reviews, 'total_average_rating');

  return (
    <div className={styles.reviewPage}>
      <LayerTitle>받은 거래후기</LayerTitle>
      <div className={styles.header}>
        <div className={styles.columns}>
          <Column value={tradedCarsCount} suffix="대" label="거래대수" />
          <Column value={reviewsCount} suffix="개" label="후기" />
          <Column value={reviewRate} suffix="%" label="작성률" />
        </div>
      </div>
      <div className={styles.body}>
        <CountByRating value={countByRating} />
        <div className={styles.divider} />
        <RatingChart rating={averageRating} />
      </div>
      <div className={styles.description}>
        <p>
          * 회원딜러 평균 평점 : <NumberEasing value={totalAverageRating} fractionDigits={1} />점
        </p>
        <p>
          * 회원딜러 평균 후기 입력률 : <NumberEasing value={averageReviewRate} fractionDigits={1} />%
        </p>
      </div>
    </div>
  );
});

export default ReviewPage;
