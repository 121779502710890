function zeroPrefix(x: number) {
  if (x <= 10) {
    return `0${x}`;
  }

  return x.toString();
}

export function toTime(x: number) {
  const seconds = Math.floor(x % 60);
  const minutes = Math.floor(x / 60 % 60);
  return `${zeroPrefix(minutes)}:${zeroPrefix(seconds)}`;
}
