import React, { FC, memo, useMemo } from 'react';
import styles from './StarRating.module.scss';

interface Props {
  rating: number;
}

const StarRating: FC<Props> = memo(({ rating }) => {
  const halfRating = useMemo(() => rating * 0.5, [rating]);
  const renderedStars = useMemo(() => {
    const stars = [];

    for (let i = 0, k = halfRating; i < 5; i++, k--) {
      const percent = Math.max(Math.min(k, 1), 0);

      stars.push(
        <div key={i} className={styles.star}>
          <div className={styles.background} style={{ width: `${percent * 100}%` }} />
          <div className={styles.image} />
        </div>
      )
    }

    return stars;
  }, [halfRating]);

  return <div className={styles.starRating}>{renderedStars}</div>;
});

export default StarRating;
