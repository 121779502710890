import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './RegisterSelect.module.scss';
import Ink from 'react-ink';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { get, map, find, size } from 'lodash';
import { Portal } from 'react-portal';
import { getPosition } from '../../cores/getPosition';

interface Props {
  variant?: 'a' | 'b';
  offset?: { x?: number; y?: number };
  className?: string;
  placeholder?: string;
  value: number | string | null;
  options?: Array<{ name: string; value: number | string | null }>;
  onChange: (value: number | string | null) => void;
}

const RegisterSelect: FC<Props> = memo(({ variant = 'a', offset, className, placeholder, value, options, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const selectedOption = find(options, option => option.value === value);
  const [minWidth, setMinWidth] = useState(0);
  const [isVisible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onRepaint = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setMinWidth(ref.current.offsetWidth - 2);

    const position = getPosition(ref.current);

    if (!position) {
      return;
    }

    setPosition(position);
  }, []);

  useEffect(() => {
    onRepaint();
  }, [isVisible]);

  useEffect(() => {
    onRepaint();
    window.addEventListener('resize', onRepaint);

    return () => {
      window.removeEventListener('resize', onRepaint);
    };
  }, []);

  return (
    <div ref={ref} className={classNames(styles.registerSelect, isVisible && styles.isVisible, get(styles, variant), className)}>
      <div
        className={styles.selectArea}
        onClick={() => {
          if (!options || size(options) <= 0) {
            return;
          }

          setVisible(true);
        }}
      >
        {selectedOption ? (
          <div className={styles.value}>{selectedOption.name}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder}</div>
        )}
        <Ink />
      </div>
      {isVisible && (
        <Portal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setVisible(false);
            }}
          >
            <div
              className={classNames(styles.options, get(styles, variant))}
              style={{
                minWidth,
                transform: `translate(${position.x + get(offset, 'x', 0)}px, ${position.y + get(offset, 'y', 0)}px)`
              }}
            >
              {map(options, ({ name, value }) => {
                const key = `${name}-${value}`;

                return (
                  <button
                    key={key}
                    className={styles.option}
                    onClick={() => {
                      onChange(value);
                      setVisible(false);
                    }}
                  >
                    {name}
                    <Ink />
                  </button>
                );
              })}
            </div>
          </OutsideClickHandler>
        </Portal>
      )}
    </div>
  );
});

export default RegisterSelect;
