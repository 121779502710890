import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { UserActions } from '../store/user';
import Cookies from 'js-cookie';
import { confirmCloseWebview } from '../cores/confirmCloseWebview';
import { ToastActions } from '../store/toast';
import { ToastVariant } from '../declaration/toast';

export default function*() {
  yield all([...createAsyncSaga(UserActions), cookieReceiver(), watchOfficeInfoUpdate()]);
}

function* watchOfficeInfoUpdate() {
  yield takeEvery(UserActions.setOfficeInfoUpdateRequest.success, function* () {
    confirmCloseWebview();
    yield put(ToastActions.createToast({ variant: ToastVariant.Success, message: '반영되었습니다.' }));
    yield delay(1000);
  });
}

export function* cookieReceiver() {
  while (true) {
    const csrftoken = Cookies.get('csrftoken');

    if (csrftoken) {
      yield put(UserActions.setToken(csrftoken));
      return;
    }

    yield delay(100);
  }
}
