import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { Study, StudyDetail } from '../declaration/study';
import { PayloadMetaAction } from 'typesafe-actions';

export enum StudyTypes {
  getStudy = '@study/getStudy',
  getStudies = '@study/getStudies',
  startStudy = '@study/startStudy',
  completeStudyVideo = '@study/completeStudyVideo',
  completeStudyReview = '@study/completeStudyReview',
  completeStudyTest = '@study/completeStudyTest',
}

export interface StudyState {
  studyMap: { [key: string]: Array<Study> };
  studyDetailMap: { [key: string]: StudyDetail };
}

export const StudyActions = {
  getStudies: createAsyncSagaAction(StudyTypes.getStudies, (hashId: string) => {
    return axios.get(`/users/${hashId}/studies/`)
  }),
  getStudy: createAsyncSagaAction(StudyTypes.getStudy, (hashId: string) => {
    return axios.get(`/studies/${hashId}/`)
  }),
  startStudy: createAsyncSagaAction(StudyTypes.startStudy, (hashId: string) => {
    return axios.post(`/studies/${hashId}/start/`)
  }),
  completeStudyVideo: createAsyncSagaAction(StudyTypes.completeStudyVideo, (hashId: string) => {
    return axios.post(`/studies/${hashId}/video/complete/`)
  }),
  completeStudyReview: createAsyncSagaAction(StudyTypes.completeStudyReview, (hashId: string, data: any) => {
    return axios.post(`/studies/${hashId}/review/complete/`, { answers: data })
  }),
  completeStudyTest: createAsyncSagaAction(StudyTypes.completeStudyTest, (hashId: string, data: any) => {
    return axios.post(`/studies/${hashId}/test/complete/`, { answers: data })
  }),
};

const initialState: StudyState = {
  studyMap: {},
  studyDetailMap: {},
};

export default handleActions<StudyState, any>(
  {
    ...createAsyncSagaReducerMap(StudyTypes.getStudies, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<Array<Study>>, [string]>) => {
        return produce(state, draft => {
          const hashId = action.meta[0];
          draft.studyMap[hashId] = action.payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(StudyTypes.getStudy, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<StudyDetail>, [string]>) => {
        return produce(state, draft => {
          const hashId = action.meta[0];
          draft.studyDetailMap[hashId] = action.payload.data;
        });
      }
    })
  },
  initialState
);
