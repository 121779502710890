import React, { FC } from 'react';
import styles from './LayerTitle.module.scss';

interface Props {

}

const LayerTitle: FC<Props> = ({ children }) => {
  return (
    <div className={styles.layerTitle}>{children}</div>
  );
};

export default LayerTitle;
