import { FC, useState } from 'react';
import styles from './WarningsPage.module.scss';
import { Profile } from '../../declaration/profile';
import classNames from 'classnames';
import Typography from '@PRNDcompany/typography';
import Button from '@PRNDcompany/button';
import { Icon } from '@PRNDcompany/icon';
import { Popup } from '@PRNDcompany/ui';
import colors from '@PRNDcompany/colors';
import { DateTime } from 'luxon';

type WarningsPageProps = {
  warnings?: Profile['warnings'];
  onOpenWarningAndFee: () => void;
};

const WarningsPage: FC<WarningsPageProps> = ({ warnings, onOpenWarningAndFee }) => {
  const [isObjectionPopupOpen, setObjectionPopupOpen] = useState(false);

  if (!warnings) return null;

  const warningsCount = warnings.warnings_count;
  const mistakesCount = warnings.mistakes_count;

  return (
    <div className={styles.warning_page}>
      <div className={styles.section_area}>
        <Typography variant="Headline_3">경고</Typography>
      </div>
      <div className={classNames(styles.section_area, styles.content_area)}>
        <div className={styles.warning_count_area}>
          <div className={styles.number_area}>
            <div>
              <Typography variant="Headline_3">{warningsCount ?? '-'}회</Typography>
              <Typography variant="Body_2">🚨 유효경고</Typography>
            </div>
            <div>
              <Typography variant="Headline_3">{mistakesCount ?? '-'}회</Typography>
              <Typography variant="Body_2">✍️ 누적 견적실수</Typography>
            </div>
          </div>
          <div className={styles.check_area}>
            {[...Array(3)].map((_, i) => (
              <div className={i < warningsCount ? styles.checked : styles.unchecked} />
            ))}
          </div>
        </div>
        <div className={classNames(styles.text_with_button_area)}>
          <div>
            <Typography variant="Body_2">
              ㆍ견적실수는 3회 시, 경고 1회로 적용됩니다. <br />
              ㆍ경고는 부여일로부터 1년이 지나면 소멸됩니다. <br />
              ㆍ경고관련 내용은 다른사람에게 공개되지 않습니다.
            </Typography>
          </div>
          <div className={styles.button_area}>
            <Button
              onClick={onOpenWarningAndFee}
              variant="secondary"
              color="gray"
              size="medium"
              startIcon={<Icon name="docu" color="$base_gray4" size={16} />}
            >
              기준열람
            </Button>
            <Button
              onClick={() => setObjectionPopupOpen(true)}
              variant="secondary"
              color="gray"
              size="medium"
              startIcon={<Icon name="hand" color="$base_gray4" size={16} />}
            >
              이의제기
            </Button>
          </div>
        </div>
      </div>
      {warnings.warning_history.length > 0 && (
        <div className={styles.history_area}>
          <Typography variant="Subtitle_2">경고 히스토리</Typography>
          {warnings.warning_history.map((history) => (
            <div className={styles.history_item}>
              <Typography variant="Body_1" color={colors.$base_gray6}>
                {DateTime.fromFormat(history.created_at, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy.MM.dd')}
              </Typography>
              <Typography variant="Body_1">경고</Typography>
            </div>
          ))}
        </div>
      )}
      <Popup
        open={isObjectionPopupOpen}
        size="mobile"
        title="이의제기 방법안내"
        variant="default"
        closeButtonProps={{ onClick: () => setObjectionPopupOpen(false), children: '확인' }}
      >
        <div className={styles.objection_popup_content}>
          <Typography variant="Body_1">
            heydealer@gmail.com으로 메일 접수해주세요. 경고를 부여 받은지 2주일 이내에 이의제기가 가능합니다. <br />
            본인 성함, 재검토를 요청하는 경고 내용과 그 이유에 대해 상세히 보내주세요.
          </Typography>
          <Typography variant="Body_1" color={colors.$base_gray6}>
            모든 제출 건에 대해 헤이딜러 관리팀이 아닌 별도 부서에서 객관적으로 재검토합니다.
          </Typography>
        </div>
      </Popup>
    </div>
  );
};

export default WarningsPage;
