import produce from 'immer';
import { handleActions } from 'redux-actions';
import axios, { AxiosResponse } from 'axios';
import { PayloadComplexes, PayloadLocationFirstParts, PayloadLocationSecondParts } from '../declaration/location';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { action, PayloadAction } from 'typesafe-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

export enum ComplexTypes {
  getLocationFirstParts = '@complex/getLocationFirstParts',
  getLocationSecondParts = '@complex/getLocationSecondParts',
  getComplexes = '@complex/getComplexes',
  truncateLocationSecondParts = '@complex/truncateLocationSecondParts',
  truncateComplexes = '@complex/truncateComplexes'
}

export interface ComplexState {
  locationFirstParts: PayloadLocationFirstParts | null;
  locationSecondParts: PayloadLocationSecondParts | null;
  complexes: PayloadComplexes | null;
}

export const ComplexActions = {
  getLocationFirstParts: createAsyncSagaAction(ComplexTypes.getLocationFirstParts, () =>
    axios.get(`/location_first_parts_for_sign_up/`)
  ),
  getLocationSecondParts: createAsyncSagaAction(ComplexTypes.getLocationSecondParts, (id: number) =>
    axios.get(`/location_first_parts/${id}/`)
  ),
  getComplexes: createAsyncSagaAction(ComplexTypes.getComplexes, (id: number) => axios.get(`/location_seconds_parts/${id}/`)),
  truncateLocationSecondParts: () => action(ComplexTypes.truncateLocationSecondParts),
  truncateComplexes: () => action(ComplexTypes.truncateComplexes),
};

const initialState: ComplexState = {
  locationFirstParts: null,
  locationSecondParts: null,
  complexes: null
};

export default handleActions<ComplexState, any>(
  {
    [ComplexTypes.truncateLocationSecondParts]: state => {
      return produce(state, draft => {
        draft.locationSecondParts = null;
      });
    },
    [ComplexTypes.truncateComplexes]: state => {
      return produce(state, draft => {
        draft.complexes = null;
      });
    },
    ...createAsyncSagaReducerMap(ComplexTypes.getLocationFirstParts, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<PayloadLocationFirstParts>>) => {
        return produce(state, draft => {
          draft.locationFirstParts = action.payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(ComplexTypes.getLocationSecondParts, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<PayloadLocationSecondParts>>) => {
        return produce(state, draft => {
          draft.locationSecondParts = action.payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(ComplexTypes.getComplexes, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<PayloadComplexes>>) => {
        return produce(state, draft => {
          draft.complexes = action.payload.data;
        });
      }
    })
  },
  initialState
);
