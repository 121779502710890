import React, { FC, memo } from 'react';
import styles from './ToastItem.module.scss';
import classNames from 'classnames';
import { config, animated, useSpring } from 'react-spring';
import { useTextWidth } from '../../hooks/useTextWidth';
import { Toast, ToastStatus, ToastVariant } from '../../declaration/toast';
import { FiX, FiInfo, FiCheck } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { ToastActions } from '../../store/toast';

interface Props {
  y: number;
}

const ToastItem: FC<Props & Toast> = memo(({ y, id, status, variant, message }) => {
  const width = useTextWidth(message) + 48;
  const dispatch = useDispatch();

  const isInfo = variant === ToastVariant.Info;
  const isError = variant === ToastVariant.Error;
  const isSuccess = variant === ToastVariant.Success;

  const isHide = status === ToastStatus.Hide;

  const spring = useSpring({
    from: {
      y: y - 0.5,
      opacity: 0
    },
    to: {
      y,
      opacity: isHide ? 0 : 1
    },
    config: isHide ? config.default : config.gentle,
    onRest: () => {
      if (isHide) {
        dispatch(ToastActions.deleteToast(id));
      }
    }
  }) as any;

  return (
    <animated.div
      style={{ width, opacity: spring.opacity, transform: spring.y.interpolate((y: number) => `translateY(${(y * 41) * -1}px)`) }}
      className={classNames(styles.toastItem, isInfo && styles.info, isError && styles.error, isSuccess && styles.success)}
    >
      <span className={styles.icon}>
        {isInfo && <FiInfo />}
        {isError && <FiX />}
        {isSuccess && <FiCheck />}
      </span>
      <span className={styles.message}>{message}</span>
    </animated.div>
  );
});

export default ToastItem;
