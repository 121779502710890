import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './StudyDetail.module.scss';
import LayerTitle from '../LayerTitle/LayerTitle';
import { set, get, map, findIndex } from 'lodash';
import classNames from 'classnames';
import StudyVideo from '../StudyVideo/StudyVideo';
import { StudyActions } from '../../store/study';
import { StudyDetail as IStudyDetail } from '../../declaration/study';
import { useDispatch } from 'react-redux';
import StudyNavigator from '../StudyNavigator/StudyNavigator';
import StudyTest from '../StudyTest/StudyTest';
import StudyReview from '../StudyReview/StudyReview';
import { SequenceActions } from '../../store/sequence';
import { useToast } from '../../hooks/useToast';
import { Portal } from 'react-portal';
import { directCloseWebview } from '../../cores/directCloseWebview';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  no: number;
  hashId: string;
  userHashId: string;
  studyDetail: IStudyDetail;
}

export const studyOrders = [
  { key: 'video', value: '영상보기' },
  { key: 'review', value: '복습하기' },
  { key: 'test', value: '퀴즈풀기' }
];

const StudyDetail: FC<Props> = memo(({ no, hashId, userHashId, studyDetail }) => {
  const { search } = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
  const history = useHistory();


  const [isCompleteVideo, setCompleteVideo] = useState(false);
  const [isCompleteReview, setCompleteReview] = useState(false);
  const [isCompleteTest, setCompleteTest] = useState(false);
  const [isCompleteAll, setCompleteAll] = useState(false);
  const [isErrorReview, setErrorReview] = useState(false);
  const [reviewAnswer, setReviewAnswer] = useState<Array<{ number: number; answer: Array<string> }>>([]);
  const [testAnswer, setTestAnswer] = useState<Array<{ number: number; answer: Array<number> }>>([]);

  const title = get(studyDetail, 'title');
  let defaultStudyOrder: { key: string; value: string } | null = null;
  const dispatch = useDispatch();

  for (let i = 0; i < studyOrders.length; i++) {
    if (!get(studyDetail, [studyOrders[i].key, 'is_completed'])) {
      defaultStudyOrder = studyOrders[i];
      break;
    }
  }

  const [studyOrder, setStudyOrder] = useState(defaultStudyOrder ? defaultStudyOrder.key : 'test');

  const isEnableFullScreen = query.get('is_enable_full_screen') === 'true';

  const video = get(studyDetail, 'video');
  const videoUrl = get(video, 'video_url');
  const videoIsCompleted = get(video, 'is_completed');

  const review = get(studyDetail, 'review');
  const reviewIsCompleted = get(review, 'is_completed');

  const test = get(studyDetail, 'test');
  const testIsCompleted = get(test, 'is_completed');

  const toast = useToast();

  const availableNext = useMemo(() => {
    switch (studyOrder) {
      case 'video':
        return videoIsCompleted || isCompleteVideo;
      case 'review':
        return reviewIsCompleted || isCompleteReview;
      case 'test':
        return testIsCompleted || isCompleteTest;
      default:
        return false;
    }
  }, [studyOrder, videoIsCompleted, reviewIsCompleted, testIsCompleted, isCompleteVideo, isCompleteReview, isCompleteTest]);

  const onNext = useCallback(() => {
    switch (studyOrder) {
      case 'video':
        if (videoIsCompleted) {
          setStudyOrder('review');
        } else if (isCompleteVideo) {
          dispatch(
            SequenceActions.createSequence([
              StudyActions.completeStudyVideo(hashId),
              StudyActions.getStudy(hashId),
              () => {
                setStudyOrder('review');
              },
              StudyActions.getStudies(userHashId)
            ])
          );
        }
        break;
      case 'review':
        if (reviewIsCompleted) {
          setStudyOrder('test');
        } else if (isCompleteReview) {
          dispatch(
            SequenceActions.createSequence([
              StudyActions.completeStudyReview(hashId, reviewAnswer),
              ({ payload }: any) => {
                if (payload instanceof Error) {
                  setErrorReview(true);
                  toast.error('정답이 아닙니다. 다시 입력해주세요.');
                } else {
                  setStudyOrder('test');
                }
              },
              StudyActions.getStudy(hashId),
              StudyActions.getStudies(userHashId)
            ])
          );
        }
        break;
      case 'test':
        if (testIsCompleted) {
        } else if (isCompleteTest) {
          dispatch(
            SequenceActions.createSequence([
              StudyActions.completeStudyTest(hashId, testAnswer),
              ({ payload }: any) => {
                if (payload instanceof Error) {
                  const wrongNumbers = get((payload as any).response.data, 'wrong_numbers');

                  setTestAnswer(prevTestAnswer => {
                    const nextTestAnswer = [...prevTestAnswer];
                    for (let i = 0; i < wrongNumbers.length; i++) {
                      const index = findIndex(nextTestAnswer, a => get(a, 'number') === wrongNumbers[i]);

                      if (index !== -1) {
                        set(nextTestAnswer[index], 'answer', []);
                      }
                    }

                    return nextTestAnswer;
                  });

                  toast.error('정답이 아닙니다. 다시 입력해주세요.');
                } else {
                  setCompleteAll(true);
                  query.set('is_completed', 'true');
                  history.push(`?${query.toString()}`);
                }
              },
              StudyActions.getStudy(hashId),
              StudyActions.getStudies(userHashId)
            ])
          );
        }
        break;
      default:
        return;
    }
  }, [
    userHashId,
    studyOrder,
    reviewAnswer,
    videoIsCompleted,
    reviewIsCompleted,
    testIsCompleted,
    isCompleteVideo,
    isCompleteReview,
    isCompleteTest,
    toast
  ]);

  const isCompletedAllServerSide = videoIsCompleted && reviewIsCompleted && testIsCompleted;

  return (
    <div className={classNames(styles.studyDetail, isEnableFullScreen && styles.isEnableFullScreen)}>
      <div className={styles.header}>
        <LayerTitle>
          STUDY {no}. {title}
        </LayerTitle>
        <div className={styles.studyOrders}>
          {map(studyOrders, ({ key, value }, index) => {
            const isCompleted = get(studyDetail, [key, 'is_completed']);
            const isActivated = studyOrder === key;

            return (
              <React.Fragment key={key}>
                <div
                  className={classNames(
                    styles.order,
                    isCompleted ? styles.isCompleted : !isActivated && styles.isDisabled,
                    isActivated && styles.isActivated
                  )}
                >
                  {value}
                </div>
                {(index === 0 || index === 1) && <span className={styles.direction} />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {studyOrder === 'video' && (
        <StudyVideo
          hashId={hashId}
          url={videoUrl}
          isCompleted={videoIsCompleted}
          onComplete={isCompleted => {
            setCompleteVideo(isCompleted);
          }}
        />
      )}
      {studyOrder === 'review' && (
        <StudyReview
          review={review}
          isError={isErrorReview}
          isCompleted={reviewIsCompleted}
          onClearError={() => {
            setErrorReview(false);
          }}
          onChange={answers => {
            setReviewAnswer(answers);
          }}
          onComplete={isCompleted => {
            setCompleteReview(isCompleted);
          }}
        />
      )}
      {studyOrder === 'test' && (
        <StudyTest
          test={test}
          isCompleted={testIsCompleted}
          value={testAnswer}
          onChange={answers => {
            setTestAnswer(answers);
          }}
          onComplete={isCompleted => {
            setCompleteTest(isCompleted);
          }}
        />
      )}
      <StudyNavigator
        value={studyOrder}
        onChange={(value: string) => setStudyOrder(value)}
        availableNext={availableNext}
        isCompletedAllServerSide={isCompletedAllServerSide}
        onNext={onNext}
      />
      {isCompleteAll && (
        <Portal>
          <div className={styles.blind} />
          <div className={styles.popup}>
            <div className={styles.image} />
            <h1 className={styles.title}>스터디 완료!</h1>
            <p className={styles.description}>스터디 완료를 축하드려요!</p>
            <button
              className={styles.exitButton}
              onClick={() => {
                directCloseWebview();
              }}
            >
              확인
            </button>
          </div>
        </Portal>
      )}
    </div>
  );
});

export default StudyDetail;
