import React, { useEffect, useRef, useState } from 'react';
import styles from './WarningAndFee.module.scss';
import Loading from '../Loading/Loading';

const WarningAndFee: React.FC<{}> = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);
  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      )}
      <iframe ref={iframeRef} src="https://api.heydealer.com/posts/GB5Rz45E/" frameBorder="0" />
    </div>
  );
};

export default WarningAndFee;
