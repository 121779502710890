import React, { FormEvent, useRef, useState } from 'react';
import styles from './TrackingReportForm.module.scss';
import classNames from 'classnames';
import TextareaField from '../TextareaField/TextareaField';
import Ink from 'react-ink';
import { useDispatch } from 'react-redux';
import { SetExplainInput, TrackingActions } from '../../store/tracking';
import { trim } from 'lodash';
import { useToast } from '../../hooks/useToast';
import { SequenceActions } from '../../store/sequence';
import { directCloseWebview } from '../../cores/directCloseWebview';

interface Props {
  trackingId: number;
}
const TrackingReportForm: React.FC<Props> = ({ trackingId }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [explanation, setExplanation] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const onClick = (index: number) => {
    if (index === 1 && textareaRef.current) {
      textareaRef.current.focus();
    }
    setSelectedIndex(index);
  };

  const onChangeTextInput = (e: FormEvent<HTMLTextAreaElement>) => {
    setExplanation(e.currentTarget.value);
  };

  const onSubmit = () => {
    const explanationType = selectedIndex === 0 ? 'admit' : 'deny';
    const data: SetExplainInput = { explanation_type: explanationType };
    if (selectedIndex === 1) {
      if (trim(explanation)) {
        data['explanation'] = explanation;
      }
    }
    dispatch(
      SequenceActions.createSequence([
        TrackingActions.setExplain(trackingId, data),
        () => {
          toast.success('입력되었습니다.');
          setTimeout(() => {
            directCloseWebview();
          }, 1500);
        },
      ])
    );
  };

  return (
    <form className={styles.form}>
      <div>
        <div className={styles.title}>매입 여부 선택하기</div>
        <div className={styles.radioGroups}>
          <div className={classNames(styles.radio, selectedIndex === 0 && styles.isSelected)} onClick={() => onClick(0)}>
            <div className={styles.shape} />
            <div className={styles.labelWrapper}>
              <div className={styles.label}>네, 헤이딜러를 통해 매입했습니다.</div>
              <div className={styles.description}>거래 예정으로 변경되니, 헤이딜러에서 거래결과 입력을 부탁드립니다.</div>
            </div>
          </div>
          <div
            className={classNames(styles.radio, selectedIndex === 1 && styles.isSelected, styles.secondRadio)}
            onClick={() => onClick(1)}
          >
            <div className={styles.shape} />
            <div className={styles.labelWrapper}>
              <div className={styles.label}>아니요. 헤이딜러를 통해 매입하지 않았습니다.</div>
              <div className={styles.description}>다른 경로(신차영업소, 개인 등)로 매입했거나, 매입하지 않은 경우</div>
              <div className={classNames(styles.textareaWrapper, { [styles.display]: selectedIndex === 1 })}>
                <TextareaField
                  ref={textareaRef}
                  value={explanation}
                  onChange={onChangeTextInput}
                  placeholder="조금 더 자세한 내용을 알려주시면, 본사에서 확인하겠습니다."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.submit} onClick={onSubmit}>
        입력하기
        <Ink />
      </div>
    </form>
  );
};

export default TrackingReportForm;
