import { all } from 'redux-saga/effects';
import user from './user';
import cars from './cars';
import toast from './toast';
import study from './study';
import spector from './spector';
import complex from './complex';
import loading from './loading';
import sequence from './sequence';
import tracking from './tracking';

export default function* rootSaga() {
  yield all([cars(), user(), study(), toast(), complex(), loading(), sequence(), tracking(), spector()]);
}
