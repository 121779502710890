import { all, put, take } from 'redux-saga/effects';
import { CreateSequenceInput, SequenceTypes } from '../store/sequence';
import { PayloadAction } from 'typesafe-actions';
import { isFunction, toArray } from 'lodash';
import { createSuffixTypes } from '../cores/createAsyncSagaAction';

function* watchCreateSequence() {
  while (true) {
    const { payload: sequences }: PayloadAction<SequenceTypes.createSequence, CreateSequenceInput> = yield take(
      SequenceTypes.createSequence
    );

    let response: any = null;
    for (let i = 0; i < sequences.length; i++) {
      const sequence = sequences[i];

      if (isFunction(sequence)) {
        sequence(response);
      } else {
        // @ts-ignore
        const action = yield put(sequence);
        if (action.type && action.type.indexOf('/INITIAL') !== -1) {
          const pureType = action.type.replace('/INITIAL', '');
          const allTypes = createSuffixTypes(pureType);

          // @ts-ignore
          delete allTypes.initial;
          // @ts-ignore
          delete allTypes.original;
          // @ts-ignore
          delete allTypes.request;
          // @ts-ignore
          response = yield take(toArray(allTypes));

          if (response.payload instanceof Error) {
            break;
          }
        }
      }
    }
  }
}

export default function* rootSaga() {
  yield all([watchCreateSequence()]);
}
