import React, { FC } from 'react';
import styles from './TradedCarsPage.module.scss';
import NumberEasing from '../NumberEasing/NumberEasing';
import { map, range } from 'lodash';
import classNames from 'classnames';
import { MonthlyTradedCars } from '../../declaration/profile';
import Typography from '@PRNDcompany/typography';
import { DateTime } from 'luxon';
import { css } from '@emotion/react';
import Button from '@PRNDcompany/button';
import { Icon } from '@PRNDcompany/icon';

interface Props {
  monthlyTradedCars?: MonthlyTradedCars;
  onOpenWarningAndFee: () => void;
}

const TradedCarsPage: FC<Props> = ({ monthlyTradedCars, onOpenWarningAndFee }) => {
  const displayCountForHeavyDealer = (countForHeavyDealer: number) => {
    return (
      <div className={styles.successWrapper}>
        {map(range(1, 6), (i) => (
          <div key={i} className={classNames(styles.success, i <= countForHeavyDealer! && styles.hasSuccess)} />
        ))}
      </div>
    );
  };
  return (
    <div className={styles.tradedCarsPage}>
      <Typography variant="Headline_3">{DateTime.local().toFormat('M')}월 거래대수</Typography>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={styles.count}>
            <Typography variant="Subtitle_2">
              일반 <NumberEasing value={monthlyTradedCars?.basic_count} />대
            </Typography>
          </div>
          {monthlyTradedCars && displayCountForHeavyDealer(monthlyTradedCars?.basic_count_for_heavy_dealer)}
        </div>
        <div className={styles.row}>
          <div className={styles.count}>
            <Typography variant="Subtitle_2">
              제로 <NumberEasing value={monthlyTradedCars?.zero_count} />대
            </Typography>
          </div>
          {monthlyTradedCars && displayCountForHeavyDealer(monthlyTradedCars?.zero_count_for_heavy_dealer)}
        </div>
      </div>
      <div className={styles.description}>
        <Typography variant="Body_2">ㆍ거래대수는 ‘거래완료일’기준 입니다.</Typography>
        <Typography
          variant="Body_2"
          css={css`
            padding-left: 1em;
            text-indent: -1em;
          `}
        >
          ㆍ일반차량 거래 월 5대 이상이면 다음달에 일반차량에 헤비딜러 할인이 적용됩니다.
        </Typography>
        <Typography
          variant="Body_2"
          css={css`
            padding-left: 1em;
            text-indent: -1em;
          `}
        >
          ㆍ제로차량 거래 월 5대 이상이면 다음달에 제로차량에 제로 헤비딜러 할인이 적용됩니다.
        </Typography>
        <Typography variant="Body_2">ㆍ단, ‘100만원 이하 차량’은 포함되지 않습니다.</Typography>
      </div>
      <Button
        onClick={onOpenWarningAndFee}
        variant="secondary"
        color="gray"
        size="medium"
        startIcon={<Icon name="docu" color="$base_gray4" size={16} />}
      >
        헤비딜러 할인
      </Button>
    </div>
  );
};

export default TradedCarsPage;
