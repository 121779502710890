import React, { FC } from 'react';
import styles from './SuccessRatePage.module.scss';
import LayerTitle from '../LayerTitle/LayerTitle';
import NumberEasing from '../NumberEasing/NumberEasing';
import classNames from 'classnames';
import SuccessRateChart from '../SuccessRateChart/SuccessRateChart';

interface Props {
  successRate?: number;
  averageSuccessRate?: number;
  selectedCarsCount?: number;
  recent100TradedCarsCount?: number;
  recent100FailedCarsCount?: number;
}

const SuccessRatePage: FC<Props> = ({ successRate, averageSuccessRate, selectedCarsCount, recent100TradedCarsCount, recent100FailedCarsCount }) => {
  let isOverHundred = false;
  if (typeof selectedCarsCount === 'number' && selectedCarsCount > 100) {
    isOverHundred = true;
    selectedCarsCount = 100;
  }

  return (
    <div className={styles.successRatePage}>
      <LayerTitle>거래 성사율</LayerTitle>
      <div className={styles.header}>
        <div className={styles.selectedCarsCount}>
          <span className={styles.label}>선택대수</span>
          <span className={styles.value}>
            <NumberEasing value={selectedCarsCount} />
            {isOverHundred && <span className={styles.percent}>+</span>}
          </span>
        </div>
        <div className={styles.divider} />
        <div className={styles.columns}>
          <div className={classNames(styles.column, styles.traded)}>
            <span className={styles.shape} />
            <span className={styles.label}>거래완료</span>
            <span className={styles.count}>
              <NumberEasing value={recent100TradedCarsCount} />
            </span>
            <span className={styles.percent}>
              (<NumberEasing value={successRate} fractionDigits={0} />
              %)
            </span>
          </div>
          <div className={classNames(styles.column, styles.failed)}>
            <span className={styles.shape} />
            <span className={styles.label}>거래실패</span>
            <span className={styles.count}>
              <NumberEasing value={recent100FailedCarsCount} />
            </span>
            <span className={styles.percent}>
              (<NumberEasing value={(recent100FailedCarsCount! / selectedCarsCount!) * 100} fractionDigits={0} />
              %)
            </span>
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <p>
          <span>*</span>
          <span>회원딜러 평균 성사율 : <NumberEasing value={averageSuccessRate} />%</span>
        </p>
        <p>
          <span>*</span>
          <span>성사율은 최근 100대 기준으로 자동 계산됩니다. </span>
        </p>
        <p>
          <span>*</span>
          <span>고객에게 노출되지 않으며, 관리팀에 문의가 불가합니다.</span>
        </p>
        <p>
          <span>*</span>
          <span>성사율이 많이 낮을 경우, 심사팀에서 피드백 드릴 수 있습니다.</span>
        </p>
      </div>
      <SuccessRateChart value={successRate} />
    </div>
  );
};

export default SuccessRatePage;
