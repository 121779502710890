import { useEffect } from 'react';

export function usePagination(id: string, callback: () => void) {
  useEffect(() => {
    const node = document.getElementById(id);

    if (node === null) {
      return;
    }

    function onScroll(e: Event) {
      const target = e.target;

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        callback();
      }
    }

    node.addEventListener('scroll', onScroll);

    return () => {
      node.removeEventListener('scroll', onScroll);
    };
  }, [id, callback]);
}
