import { all, take } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { StudyActions } from '../store/study';

export default function*() {
  yield all([...createAsyncSaga(StudyActions), watchSubmitTestSuccess()]);
}

function* watchSubmitTestSuccess() {
  while (true) {
    // @ts-ignore
    const payload = yield take(StudyActions.completeStudyTest.success);
  }
}
