import { combineReducers } from 'redux';
import cars, { CarsState } from './cars';
import user, { UserState } from './user';
import toast, { ToastState } from './toast';
import study, { StudyState } from './study';
import complex, { ComplexState } from './complex';
import loading, { LoadingState } from './loading';
import sequence, { SequenceState } from './sequence';
import tracking, {TrackingState} from './tracking';

export default combineReducers<Application>({
  cars,
  user,
  study,
  toast,
  complex,
  loading,
  sequence,
  tracking,
});

export interface Application {
  cars: CarsState;
  user: UserState;
  study: StudyState;
  toast: ToastState;
  complex: ComplexState;
  loading: LoadingState;
  sequence: SequenceState;
  tracking: TrackingState;
}
