import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './StudyPage.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StudyActions } from '../../store/study';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, map, findIndex } from 'lodash';
import StudyDetail from '../StudyDetail/StudyDetail';

interface Props {}

const StudyPage: FC<Props> = memo(() => {
  const { search } = useLocation();
  const history = useHistory();
  const { hashId: userHashId } = useParams<{ hashId: string }>();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const hashId = query.get('hash_id');
  const dispatch = useDispatch();

  const { no, studyDetail } = useTypedSelector(({ study: { studyMap, studyDetailMap } }) => {
    return {
      no: findIndex(userHashId ? get(studyMap, userHashId) : [], study => get(study, 'hash_id') === hashId) + 1,
      studyDetail: hashId ? get(studyDetailMap, hashId) : null
    };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(StudyActions.getStudy(hashId));
    dispatch(StudyActions.startStudy(hashId));
  }, [hashId, dispatch]);

  useEffect(() => {
    return () => {
      if (!userHashId) {
        return;
      }

      dispatch(StudyActions.getStudies(userHashId));
    };
  }, [userHashId]);

  if (no === -1 || no === 0 || !userHashId || !hashId || !studyDetail) {
    return null;
  }

  return (
    <div className={styles.studyPage}>
      <StudyDetail no={no} hashId={hashId} userHashId={userHashId} studyDetail={studyDetail} />
    </div>
  );
});

export default StudyPage;
