import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import PreviewCars from 'src/components/PreviewCars/PreviewCars';
import SuccessRate from 'src/components/SuccessRatePage/SuccessRatePage';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { UserActions } from 'src/store/user';

const SuccessRatePage: FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const dispatch = useDispatch();

  const { profile } = useTypedSelector(({ user: { profileMap } }) => {
    return { profile: hashId ? profileMap[hashId] || null : null };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserProfile(hashId));
  }, [dispatch, hashId]);

  return (
    <div>
      <SuccessRate
        successRate={profile?.trades.success_rate}
        averageSuccessRate={profile?.trades.average_success_rate}
        selectedCarsCount={profile?.trades.selected_cars_count}
        recent100TradedCarsCount={profile?.trades.recent_100_traded_cars_count}
        recent100FailedCarsCount={profile?.trades.recent_100_failed_cars_count}
      />
      <PreviewCars hashId={hashId} />
    </div>
  );
};

export default SuccessRatePage;
