import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import ProfileDetail from 'src/components/ProfileDetail/ProfileDetail';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { UserActions } from 'src/store/user';

const ProfileDetailPage: FC = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const dispatch = useDispatch();

  const { profile } = useTypedSelector(({ user: { profileMap } }) => {
    return { profile: hashId ? profileMap[hashId] || null : null };
  });

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserProfile(hashId));
  }, [dispatch, hashId]);

  return profile && <ProfileDetail data={profile} />;
};

export default ProfileDetailPage;
