import React, { CSSProperties, FC, forwardRef, ForwardRefRenderFunction, HTMLAttributes, TextareaHTMLAttributes } from 'react';
import styles from './TextareaField.module.scss';
import classNames from 'classnames';

interface Props {
  style?: CSSProperties;
  name?: string;
  value?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}

const TextareaField: ForwardRefRenderFunction<HTMLTextAreaElement, Props & HTMLAttributes<HTMLTextAreaElement>> = (
  { style, name, value, className, maxLength, ...otherProps },
  ref
) => {
  return (
    <textarea
      ref={ref}
      style={style}
      className={classNames(styles.input, className)}
      name={name}
      value={value}
      maxLength={maxLength}
      {...otherProps}
    />
  );
};

export default forwardRef(TextareaField);
