import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import { store } from '../index';
import { ToastActions } from '../store/toast';

export const requester = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
});

const treatPxiosResponse = (promise: AxiosPromise) => {
  return promise
    .then((response) => response)
    .catch((err: any) => {
      const message = get(err, ['response', 'data', 'toast', 'message']);
      const type = get(err, ['response', 'data', 'toast', 'type']);
      if (message) {
        // Todo thunkAction으로 변경(any 타입 없애기)
        store.dispatch<any>(ToastActions.createToast({ message, variant: type || 'default' }));
      }
      throw new Error(err.response.data.toast.message);
    });
};

class Pxios {
  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.get(url, config));
  }
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.put(url, data, config));
  }
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.post(url, data, config));
  }
  delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.delete(url, config));
  }
  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return treatPxiosResponse(requester.patch(url, data, config));
  }
}

const pxios = new Pxios();
export default pxios;
