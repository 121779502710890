import { get } from 'lodash';
import React, { FC, useEffect } from 'react';
import styles from './PreviewCars.module.scss';
import { useDispatch } from 'react-redux';
import { CarsActions, CarsTypes } from '../../store/cars';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import PreviewCarsGroup from '../PreviewCarsGroup/PreviewCarsGroup';
import Loading from '../Loading/Loading';

import classNames from 'classnames';

interface Props {
  hashId: string;
}

const PreviewCars: FC<Props> = ({ hashId }) => {
  const dispatch = useDispatch();

  const createOnOpenCars = (type: 'traded' | 'failed') => () =>
    (window.location.href = `heydealerfordealer://webview?url=${encodeURIComponent(
      `${window.location.protocol}//${window.location.host}/profile/${hashId}/cars?type=${type}`
    )}&toolbar=true`);

  const {
    isLoading,
    previewTradedCars,
    previewFailedCars,
    previewTradedCarsCount,
    previewFailedCarsCount,
    isLoadedTradedCars,
    isLoadedFailedCars,
  } = useTypedSelector(
    ({
      cars: {
        previewTradedCars,
        previewFailedCars,
        previewTradedCarsCount,
        previewFailedCarsCount,
        isLoadedTradedCars,
        isLoadedFailedCars,
      },
      loading: { asyncMap },
    }) => {
      return {
        previewTradedCars,
        previewFailedCars,
        previewTradedCarsCount,
        previewFailedCarsCount,
        isLoadedTradedCars,
        isLoadedFailedCars,
        isLoading: get(asyncMap, CarsTypes.getPreviewTradedCars) > 0 || get(asyncMap, CarsTypes.getPreviewFailedCars) > 0,
      };
    }
  );

  useEffect(() => {
    dispatch(CarsActions.getPreviewTradedCars());
    dispatch(CarsActions.getPreviewFailedCars());
  }, [dispatch]);

  const isEmptyTradedCars = previewTradedCarsCount === 0 && isLoadedTradedCars;
  const isEmptyFailedCars = previewFailedCarsCount === 0 && isLoadedFailedCars;
  const isEmpty = isEmptyTradedCars && isEmptyFailedCars;

  return (
    <div className={classNames(styles.previewCars, isEmpty && styles.isEmpty)}>
      {isLoading ? (
        <Loading />
      ) : isEmpty ? (
        <div className={styles.empty}>
          <h1 className={styles.title}>거래내역이 없습니다.</h1>
        </div>
      ) : (
        <React.Fragment>
          {!isEmptyTradedCars && (
            <PreviewCarsGroup
              title="거래완료 차량"
              data={previewTradedCars}
              count={previewTradedCarsCount}
              onClick={createOnOpenCars('traded')}
            />
          )}
          {!isEmptyFailedCars && (
            <PreviewCarsGroup
              title="거래실패 차량"
              data={previewFailedCars}
              count={previewFailedCarsCount}
              onClick={createOnOpenCars('failed')}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default PreviewCars;
