import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './RatingChart.module.scss';
import { easeCubicInOut } from 'd3-ease';
import { Portal } from 'react-portal';
import Ink from 'react-ink';

interface Props {
  rating?: number;
}

const width = 104 * 2;
const height = 104 * 2;
const halfWidth = width * 0.5;
const halfHeight = height * 0.5;

const PI = Math.PI;
const PIHalf = PI * 0.5;
const PI2 = PI * 2;

const RatingChart: FC<Props> = ({ rating }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const [isVisibleAdditionalModal, setVisibleAdditionalModal] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let cancelRequestAnimationId: number | null = null;
    let startedAt = Date.now();
    let delta = 0;
    let animation = 0;

    const percent = (rating || 0) / 10;
    const context = ref.current.getContext('2d')!;

    const render = () => {
      cancelRequestAnimationId = window.requestAnimationFrame(render);

      const currentAt = Date.now();
      if (currentAt - startedAt > 0) {
        delta = (currentAt - startedAt) * 0.001;
        startedAt = currentAt;
      }

      animation += delta;

      if (animation > 1) {
        animation = 1;
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }

      const easing = easeCubicInOut(animation);

      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.strokeStyle = '#e9edf4';
      context.lineWidth = 16;
      context.arc(halfWidth, halfHeight, 96, 0, PI2);
      context.stroke();

      context.beginPath();
      context.strokeStyle = '#396eff';
      context.arc(halfWidth, halfHeight, 96, PI + PIHalf - PI2 * percent * easing, PI + PIHalf);
      context.stroke();

      context.beginPath();
      context.font = 'normal 48px "SDGothicNeo1"';
      context.textBaseline = 'top';
      context.fillStyle = typeof rating === 'number' ? '#396eff' : '#414d6b';
      const text = typeof rating === 'number' ? (rating * easing).toFixed(1) : '-';
      const { width: textWidth } = context.measureText(text);

      context.fillText(text, halfWidth - textWidth * 0.5, 70);
      context.font = 'normal 22px "SDGothicNeo1"';
      context.fillStyle = '#414d6b';
      context.fillText('평점', halfWidth * 0.5 + 15, 130);
    };

    cancelRequestAnimationId = window.requestAnimationFrame(render);

    return () => {
      if (cancelRequestAnimationId !== null) {
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }
    };
  }, [rating]);

  return (
    <div className={styles.ratingChart}>
      <canvas className={styles.canvas} ref={ref} width={width} height={height} />
      <button
        className={styles.question}
        onClick={() => {
          setVisibleAdditionalModal(true);
        }}
      />
      {isVisibleAdditionalModal && (
        <Portal>
          <div className={styles.portal}>
            <div className={styles.additionalModal}>
              <div className={styles.inner}>
                <h1 className={styles.title}>평점 계산기준</h1>
                <p className={styles.p}>
                  <span>-</span>
                  <span>최근 30대 거래를 기준으로 계산됩니다.</span>
                </p>
                <p className={styles.p}>
                  <span>
                    - <b>거래취소 고객의 평가</b>도 반영됩니다.
                  </span>
                </p>
              </div>
              <div className={styles.actions}>
                <button
                  className={styles.ok}
                  onClick={() => {
                    setVisibleAdditionalModal(false);
                  }}
                >
                  확인
                  <Ink />
                </button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default RatingChart;
