export function getTextWidth(text: string, fontSize?: number | string) {
  const span = document.createElement('span');
  span.innerText = text;
  document.body.appendChild(span);
  if (fontSize) {
    span.style.fontSize = fontSize.toString();
  }
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
}
