import React, { FC } from 'react';
import styles from './StudyItem.module.scss';
import classNames from 'classnames';
import Ink from 'react-ink';

interface Props {
  no: number;
  title: string;
  hashId: string;
  category: string;
  progress: number;
  blockedReason: string | null;
  isBlocked: boolean;
  onClick: () => void;
}

const StudyItem: FC<Props> = ({ no, title, category, progress, isBlocked, blockedReason, onClick }) => {
  return (
    <div className={classNames(styles.studyItem, isBlocked && styles.isBlocked)} onClick={() => {
      if (!isBlocked) {
        onClick();
      }
    }}>
      <div className={styles.header}>
        <h1 className={styles.no}>STUDY {no}</h1>
        <h2 className={styles.title}>{title}</h2>
        {isBlocked ? (
          <div className={styles.blockedReason}>
            {blockedReason || '-'}
          </div>
        ) : (
          <div className={styles.progressWrap}>
            <div className={styles.progress}>
              <div className={styles.inner} style={{ width: `${progress}%` }} />
            </div>
            <div className={styles.percentage}>
              {progress}%
            </div>
          </div>
        )}
      </div>
      <div className={styles.play} />
      {!isBlocked && <Ink />}
    </div>
  );
};

export default StudyItem;
