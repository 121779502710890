import { get } from 'lodash';
import React, { FC } from 'react';
import styles from './ProfileDetail.module.scss';
import { Profile } from '../../declaration/profile';
import ImageLoader from '../ImageLoader/ImageLoader';
import { getResizeUrl } from '../../cores/getResizeUrl';
import { toDashedPhoneNumber } from '../../cores/toDashedPhoneNumber';
import { DateTime } from 'luxon';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Cookies from 'js-cookie';

interface Props {
  data: Profile;
}

const Field: FC<{ label: string; value: string }> = ({ label, value, children }) => {
  return (
    <div className={styles.field}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );
};

const ProfileDetail: FC<Props> = ({ data }) => {
  const basicInformation = get(data, 'basic_information');
  const fullName = get(basicInformation, 'full_name');
  const office = get(basicInformation, 'office_display');
  const officePhoneNumber = get(basicInformation, 'office_phone_number');
  const profileImageUrl = get(basicInformation, 'profile_image_url');
  const backgroundImageUrl = get(basicInformation, 'background_image_url');
  const introduction = get(basicInformation, 'introduction');
  const phoneNumber = get(basicInformation, 'phone_number');
  const locationFirstPart = get(basicInformation, 'location_first_part');
  const locationSecondPart = get(basicInformation, 'location_second_part');
  const idCardExpirationDate = get(basicInformation, 'id_card_expiration_date');
  const email = get(basicInformation, 'email');
  const complex = get(basicInformation, 'complex');
  const address = get(basicInformation, 'address');

  const idCardExpirationDateDisplay = idCardExpirationDate
    ? DateTime.fromFormat(idCardExpirationDate, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd')
    : '-';
  const contact = Cookies.get('contact');

  return (
    <div className={styles.profileDetail}>
      <div className={styles.background}>
        <ImageLoader className={styles.backgroundImage} image={getResizeUrl(500, 300, backgroundImageUrl)} />
      </div>
      <div className={styles.header}>
        <div className={styles.profile}>
          <ImageLoader className={styles.profileImage} image={getResizeUrl(200, 200, profileImageUrl)} />
        </div>
        <div className={styles.information}>
          <h1 className={styles.fullName}>{fullName}</h1>
          <p className={styles.office}>{office}</p>
        </div>
      </div>
      <div className={styles.description}>
        <div className={styles.inner}>{introduction}</div>
      </div>
      <Field label="핸드폰 번호" value={phoneNumber ? toDashedPhoneNumber(phoneNumber) : '-'} />
      <Field label="이메일" value={email} />
      <Field label="사원증 유효기간" value={idCardExpirationDateDisplay} />
      <Field label="매매단지" value={`${locationFirstPart} / ${locationSecondPart} / ${complex}`}>
        <div className={styles.officePhoneNumber}>{toDashedPhoneNumber(officePhoneNumber)}</div>
        <div className={styles.officeAddress}>{address}</div>
      </Field>
      <div className={styles.actions}>
        <a href={contact ? contact : 'https://heydealerfd.channel.io'}>정보변경 요청</a>
      </div>
    </div>
  );
};

export default ProfileDetail;
