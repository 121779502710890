import React, { FC, memo, useEffect, useRef } from 'react';
import styles from './ReviewPanel.module.scss';
import NumberEasing from '../NumberEasing/NumberEasing';
import StarImage from '../../images/checkbox_normal.png';
import { easeCubicInOut } from 'd3-ease';
import { UserActions } from '../../store/user';
import { useDispatch } from 'react-redux';

const starImage = new Image();
starImage.src = StarImage;

interface Props {
  reviewsCount?: number;
  reviewsRate?: number;
  rating?: number;
  onClick: () => void;
}

const width = 84 * 2;
const height = 84 * 2;
const halfWidth = width * 0.5;
const halfHeight = height * 0.5;

const PI = Math.PI;
const PIHalf = PI * 0.5;
const PI2 = PI * 2;

const ReviewPanel: FC<Props> = memo(({ reviewsCount, reviewsRate, rating, onClick }) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let cancelRequestAnimationId: number | null = null;
    let startedAt = Date.now();
    let delta = 0;
    let animation = 0;

    const percent = (rating || 0) / 10;
    const context = ref.current.getContext('2d')!;

    const render = () => {
      cancelRequestAnimationId = window.requestAnimationFrame(render);

      const currentAt = Date.now();
      if (currentAt - startedAt > 0) {
        delta = (currentAt - startedAt) * 0.001;
        startedAt = currentAt;
      }

      animation += delta;

      if (animation > 1) {
        animation = 1;
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }

      const easing = easeCubicInOut(animation);

      context.lineCap = 'round';
      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.strokeStyle = 'rgba(255, 255, 255, .1)';
      context.lineWidth = 8;
      context.arc(halfWidth, halfHeight, 80, 0, PI2);
      context.stroke();

      context.beginPath();
      context.strokeStyle = '#fff';
      context.arc(halfWidth, halfHeight, 80, PI + PIHalf - PI2 * percent * easing, PI + PIHalf);
      context.stroke();

      const text = typeof rating === 'number' ? (rating * easing).toFixed(1) : '-';
      const { width: textWidth } = context.measureText(text);

      context.font = 'normal 48px "SDGothicNeo1"';
      context.textBaseline = "top";
      context.fillStyle = '#fff';
      context.beginPath();
      context.fillText(text, halfWidth - textWidth * 0.5, 44);

      context.beginPath();
      context.drawImage(starImage, halfWidth - 16, 100, 32, 32)
    };

    cancelRequestAnimationId = window.requestAnimationFrame(render);

    return () => {
      if (cancelRequestAnimationId !== null) {
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }
    };
  }, [rating]);

  const isPending = rating === undefined || isNaN(rating);

  return (
    <div className={styles.reviewPanel} onClick={onClick}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.value}>
            <NumberEasing value={reviewsCount} />
          </div>
          <div className={styles.label}>거래후기</div>
        </div>
        <div className={styles.column}>
          <div className={styles.value}>
            <NumberEasing value={reviewsRate} />{!isPending && <span style={{ fontSize: 12 }}>%</span>}
          </div>
          <div className={styles.label}>작성률</div>
        </div>
      </div>
      <canvas className={styles.canvas} ref={ref} width={width} height={height} />
    </div>
  );
});

export default ReviewPanel;
