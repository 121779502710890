import React, { FC } from 'react';
import styles from './CountByRating.module.scss';
import { get, sumBy } from 'lodash';
import NumberEasing from '../NumberEasing/NumberEasing';
import { animated, useSpring } from 'react-spring';

interface Props {
  value?: Array<number>;
}

const Rating: FC<{ num: number; count: number; percent: number }> = ({ num, count, percent }) => {
  const spring = useSpring({ x: isNaN(percent) ? 0 : percent });

  return (
    <div className={styles.rating}>
      <span className={styles.star} />
      <span className={styles.num}>{num}</span>
      <span className={styles.progress}>
        <animated.span className={styles.inner} style={{ width: spring.x.interpolate((x) => `${x}%`) }} />
      </span>
      <span className={styles.count}>
        <NumberEasing value={count} />
      </span>
    </div>
  );
};

const CountByRating: FC<Props> = ({ value }) => {
  const ratings = [];
  const total = sumBy(value) || 0;

  for (let i = 0; i < 10; i += 2) {
    const index = 10 - i;
    const count = get(value, index - 1, 0) + get(value, index - 2, 0);
    const percent = count / total * 100;

    ratings.push(<Rating key={i} num={index} count={count} percent={percent} />);
  }

  return <div className={styles.countByRating}>{ratings}</div>;
};

export default CountByRating;
