import React, { FC, useCallback } from 'react';
import styles from './CloseButton.module.scss';
import { confirmCloseWebview } from '../../cores/confirmCloseWebview';
import classNames from 'classnames';

interface Props {
  className?: string;
  isConfirm?: boolean;
}

const CloseButton: FC<Props> = ({ isConfirm = false, className }) => {
  const onClose = useCallback(() => {
    confirmCloseWebview(isConfirm);
  }, [isConfirm]);

  return (
    <div className={classNames(styles.closeButton, className)}>
      <button
        className={styles.back}
        onClick={onClose}
      />
    </div>
  );
};

export default CloseButton;
