import React, { FC } from 'react';
import styles from './Loading.module.scss';
import { get } from 'lodash';
import classNames from 'classnames';

interface Props {
  variant?: 'default' | 'white';
  className?: string;
}

const Loading: FC<Props> = ({ variant = 'default', className }) => {
  return (
    <svg className={classNames(styles.loading, className, variant !== 'default' && get(styles, variant))} width="50px" height="50px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <circle className={styles.path} fill="none" strokeWidth="3" strokeLinecap="round" cx="25" cy="25" r="15" />
    </svg>
  );
};

export default Loading;
