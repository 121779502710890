import { all, put, take } from 'redux-saga/effects';
import { get, isError, toString } from 'lodash';
import { AxiosResponse } from 'axios';
import { ToastActions, ToastTypes } from '../store/toast';

function* watchAsyncActionSaga() {
  while (true) {
    try {
      const { payload } = yield take('*');

      if (isError(payload)) {
        const response: AxiosResponse<{ toast_message: string | null }> = get(payload, 'response');
        const status = get(response, 'status');
        const toastMessage = get(response, ['data', 'toast', 'message']);

        if (toastMessage) {
          yield put(ToastActions.createToast({ variant: 1, message: toastMessage }));
        } else if (/5\d*/.test(toString(status))) {
          yield put(ToastActions.createToast({ variant: 1, message: '알 수 없는 오류가 발생했습니다.' }));
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default function* rootSaga() {
  yield all([watchAsyncActionSaga()]);
}
