import React, { FC, memo, ReactNode } from 'react';
import styles from './QuestionItem.module.scss';
import classNames from 'classnames';

interface Props {
  index: number;
  title: string;
  currentValue: string;
  which: boolean | null;
  onChangeWhich: (which: boolean) => void;
  falselyComponent: ReactNode;
}

const QuestionRadio: FC<{ name: string; isSelected: boolean; onClick: () => void }> = ({ name, isSelected, onClick }) => {
  return (
    <div className={classNames(styles.questionRadio, isSelected && styles.isSelected)} onClick={onClick}>
      <span className={styles.shape} />
      <span className={styles.name}>{name}</span>
    </div>
  );
};

const QuestionItem: FC<Props> = memo(({ index, title, currentValue, which, onChangeWhich, falselyComponent }) => {
  const isFalsely = which === true;
  return (
    <div className={styles.questionItem}>
      <div className={styles.header}>
        <div className={styles.index}>Q{index}</div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.currentValue}>
            <span className={styles.text}>{currentValue}</span>
          </div>
        </div>
        <div>
          <QuestionRadio name="맞습니다." isSelected={which === false} onClick={() => onChangeWhich(false)} />
          <QuestionRadio name="아닙니다. (수정하기)" isSelected={isFalsely} onClick={() => onChangeWhich(true)} />
        </div>
        {isFalsely && falselyComponent}
      </div>
    </div>
  );
});

export default QuestionItem;
