import React, { CSSProperties, FC, memo, useEffect, useMemo, useState } from 'react';
import styles from './StudyReview.module.scss';
import { Review } from '../../declaration/study';
import { chain, get, range, map } from 'lodash';
import { getTextWidth } from '../../cores/getTextWidth';
import classNames from 'classnames';
import { getPosition } from '../../cores/getPosition';

interface Props {
  review: Review;
  isError: boolean;
  isCompleted: boolean;
  onChange: (answers: Array<{ number: number; answer: Array<string> }>) => void;
  onComplete: (isCompleted: boolean) => void;
  onClearError: (isError: boolean) => void;
}

interface Question {
  type: 'text' | 'answer';
  value: string;
  options?: {
    style?: CSSProperties;
  };
}

const StudyReview: FC<Props> = memo(({ isError, isCompleted, review, onChange, onComplete, onClearError }) => {
  const reviewQuestions = get(review, 'review_questions');
  const [fields, setFields] = useState<string[][]>(map(range(reviewQuestions.length), () => []));
  const [errorFields, setErrorFields] = useState<{ [key: number]: { [key: number]: boolean } }>({});

  const questions = useMemo(() => {
    const questions: Array<Array<Question>> = [];

    for (let i = 0; i < reviewQuestions.length; i++) {
      questions.push([]);

      const answers = get(reviewQuestions[i], 'answer');
      const message = get(reviewQuestions[i], 'question');
      const messageSplits = message.split(/\[\s?[^\]]+\s?\]/g);

      for (let j = 0; j < messageSplits.length; j++) {
        const k = j - 1;
        const answer = get(answers, k);

        if (answer) {
          questions[i].push({
            type: 'answer',
            value: answer,
            options: {
              style: { width: getTextWidth(answer, 14) + 8 }
            }
          });
        }

        if (message) {
          questions[i].push({
            type: 'text',
            value: messageSplits[j]
          });
        }
      }
    }

    return questions;
  }, [reviewQuestions]);

  const correctFields = useMemo(() => {
    return chain(questions)
      .map(nodes =>
        chain(nodes)
          .filter(({ type }) => type === 'answer')
          .map(v => v.value)
          .value()
      )
      .value();
  }, [questions]);

  useEffect(() => {
    onComplete(true);
  }, [fields, onComplete]);

  useEffect(() => {
    if (isError) {
      const nextErrorFields: { [key: number]: { [key: number]: boolean } } = {};

      setFields(prevFields => {
        const nextFields = [...prevFields];

        for (let y = 0; y < correctFields.length; y++) {
          for (let x = 0; x < correctFields[y].length; x++) {
            if (correctFields[y][x] !== nextFields[y][x]) {
              nextFields[y][x] = '';
              if (!nextErrorFields[y]) {
                nextErrorFields[y] = {};
              }
              nextErrorFields[y][x] = true;
            }
          }
        }

        setErrorFields(nextErrorFields);
        onClearError(false);

        return nextFields;
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isCompleted) {
      setFields(correctFields);
    }
  }, [isCompleted, questions, correctFields]);

  useEffect(() => {
    onChange(map(fields, (field, key) => ({ number: key + 1, answer: field })));
  }, [fields]);

  return (
    <div className={styles.studyReview}>
      <h2 className={styles.message}>빈칸의 문자를 가이드 대로 작성해주세요.</h2>
      <div className={styles.questions}>
        {map(questions, (nodes, y) => {
          let index = -1;

          return (
            <div key={y} className={styles.question}>
              {map(nodes, ({ type, value, options }, x) => {
                if (type === 'text') {
                  return <span dangerouslySetInnerHTML={{ __html: value }} key={x} />;
                } else if (type === 'answer') {
                  index++;

                  return (
                    <span key={x}>
                      <input
                        data-key={index}
                        className={classNames(
                          styles.word,
                          isCompleted && styles.isDisabled,
                          get(errorFields, [y, index]) && styles.isError
                        )}
                        style={get(options, 'style')}
                        type="text"
                        value={fields[y][index] || ''}
                        placeholder={value}
                        onFocus={e => {
                          const position = getPosition(e.target);
                          const node = document.getElementById('study');

                          if (node === null) {
                            return;
                          }

                          if (position !== null) {
                            setTimeout(() => {
                              node.scrollTo(0, position.y - 112);
                            }, 50);
                          }
                        }}
                        readOnly={isCompleted}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          const value = e.currentTarget.value;
                          const key = parseInt(e.currentTarget.getAttribute('data-key') || '');

                          setFields(prevFields => {
                            const nextFields = [...prevFields];
                            nextFields[y][key] = value;
                            return nextFields;
                          });
                        }}
                      />
                    </span>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default StudyReview;
