// IE11의 경우
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// intersection-observer polyfill
import 'intersection-observer';
import './styles/index.module.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';
import App from './components/App/App';
import Toasts from './components/Toasts/Toasts';
import createStore from './cores/createStore';
import { QueryClient, QueryClientProvider } from 'react-query';
import pxios from './apis/pxios';

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

export const store = createStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: url }) => {
        const { data } = await pxios.get(`${url}`);
        return data;
      },
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <Toasts />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
