import React from 'react';
import styles from './Loader.module.scss';
import colors from '@PRNDcompany/colors';
import { ClipLoader } from 'react-spinners';

const Loader: React.FC<{}> = () => {
  return (
    <div className={styles.loader}>
      <ClipLoader color={colors.$brand_primary} size={28} />
    </div>
  );
};

export default Loader;
