import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { PayloadAction } from 'typesafe-actions';
import { TrackingResult } from '../declaration/tracking';

export enum TrackingTypes {
  GET_TRACKING_RESULT = '@tracking/GET_TRACKING_RESULT',
  SET_EXPLAIN = '@tracking/SET_EXPLAIN',
}

export interface TrackingState {
  trackingResult: TrackingResult | null;
}

type ExplanationType = 'admit' | 'deny';

export interface SetExplainInput {
  explanation_type: ExplanationType;
  explanation?: string;
}

export const TrackingActions = {
  getTrackingResult: createAsyncSagaAction(TrackingTypes.GET_TRACKING_RESULT, (trackingId: number) => {
    return axios.get(`/tracking_results/${trackingId}`);
  }),
  setExplain: createAsyncSagaAction(
    TrackingTypes.SET_EXPLAIN,
    (trackingId: number, data: SetExplainInput) => axios.post(`/tracking_results/${trackingId}/explain/`, data)
  ),
};

const initialState: TrackingState = {
  trackingResult: null,
};

export default handleActions<TrackingState, any>(
  {
    ...createAsyncSagaReducerMap(TrackingTypes.GET_TRACKING_RESULT, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<TrackingResult>>) => {
        return produce(state, (draft) => {
          draft.trackingResult = action.payload.data;
        });
      },
    }),
  },
  initialState
);
