import { all, put, delay, takeEvery } from 'redux-saga/effects';
import { get, size } from 'lodash';
import { PayloadAction } from 'typesafe-actions';
import { ToastActions, ToastTypes } from '../store/toast';
import { Toast, ToastStatus, ToastVariant } from '../declaration/toast';

export default function*() {
  yield all([watchAsyncSagaActions(), watchCreateToastAction()]);
}

function* watchCreateToastAction() {
  yield takeEvery(ToastTypes.createToast, function*(action: PayloadAction<string, Toast>) {
    const toast = action.payload;
    yield delay(toast.duration);
    yield put(ToastActions.updateToast({ id: toast.id, status: ToastStatus.Hide }));
  });
}

function* watchAsyncSagaActions() {
  yield takeEvery('*', function*(action: PayloadAction<string, any>) {
    if (/\/FAILURE$/g.test(action.type)) {
      const errors = get(action, ['payload', 'graphQLErrors']);

      // for (let i = 0; i < size(errors); i++) {
      //   const message = get(errors, [i, 'message']);
      //
      //   if (message) {
      //     yield put(ToastActions.createToast({ variant: ToastVariant.Error, message }));
      //   }
      // }
    }
  });
}
