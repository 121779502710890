import React, { FC, useMemo } from 'react';
import styles from './StudyNavigator.module.scss';
import Ink from 'react-ink';
import classNames from 'classnames';
import { studyOrders } from '../StudyDetail/StudyDetail';
import { get, findIndex } from 'lodash';
import { Portal } from 'react-portal';
import { useLocation } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';

interface Props {
  value: string;
  onNext: () => void;
  onChange: (value: string) => void;
  availableNext: boolean;
  isCompletedAllServerSide: boolean;
}

const StudyNavigator: FC<Props> = ({ value, onNext, onChange, availableNext, isCompletedAllServerSide }) => {
  const prevIndex = findIndex(studyOrders, order => order.key === value) - 1;
  const prev = get(studyOrders, [prevIndex, 'key']);
  const { search } = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const toast = useToast();
  const isEnableFullScreen = query.get('is_enable_full_screen') === 'true';

  return (
    <Portal>
      <div className={classNames(styles.studyNavigator, isEnableFullScreen && styles.isEnableFullScreen)}>
        <div>
          {prev && (
            <button
              className={styles.prev}
              onClick={() => {
                onChange(prev);
              }}
            >
              이전으로
              <Ink />
            </button>
          )}
        </div>
        <div>
          {!(value === 'test' && isCompletedAllServerSide) && (
            <button className={classNames(styles.next, availableNext && styles.availableNext)} onClick={() => {
              onNext();

              if (value === 'video' && !availableNext) {
                toast.error('먼저 스터디 영상을 시청해주세요!');
              } else if (value === 'review' && !availableNext) {
                toast.error('복습하기를 진행해주세요!');
              }
            }}>
              {value === 'test' ? '제출하기' : '다음으로 >'}
              <Ink />
            </button>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default StudyNavigator;
