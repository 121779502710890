import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import styles from './Study.module.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { map, get } from 'lodash';
import { StudyActions } from '../../store/study';
import StudyItem from '../StudyItem/StudyItem';
import CloseButton from '../CloseButton/CloseButton';
import Layer from '../Layer/Layer';
import StudyPage from '../StudyPage/StudyPage';

interface Props {}

const Study: FC<Props> = memo(() => {
  const { hashId } = useParams<{ hashId: string }>();
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const dispatch = useDispatch();

  const studyHashId = query.get('study_hash_id');

  const { studies } = useTypedSelector(({ study: { studyMap } }) => {
    return {
      studies: hashId ? get(studyMap, hashId) || null : null
    };
  });

  useEffect(() => {
    if (!studyHashId) {
      return;
    }

    query.delete('study_hash_id');
    query.set('hash_id', studyHashId);
    query.set('layer', 'study');
    query.set('direct', 'true');
    history.replace(`?${query.toString()}`);
  }, [studyHashId]);

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(StudyActions.getStudies(hashId));
  }, [dispatch, hashId]);

  const onOpenStudyLayer = useCallback(
    (hashId: string) => {
      return () => {
        const layers = query.getAll('layer');

        if (layers.indexOf('study') === -1) {
          query.delete('layer');
          query.set('hash_id', hashId);
          layers.push('study');

          for (let i = 0, length = layers.length; i < length; i++) {
            query.append('layer', layers[i]);
          }

          history.push(`?${query.toString()}`);
        }
      };
    },
    [query]
  );

  return (
    <div className={styles.study}>
      <CloseButton />
      <h1 className={styles.title}>딜러 스터디</h1>
      <div className={styles.studies}>
        {map(studies, (study, key) => {
          const title = get(study, 'title');
          const hashId = get(study, 'hash_id');
          const category = get(study, 'category');
          const isBlocked = get(study, 'is_blocked');
          const blockedReason = get(study, 'blocked_reason');
          const progressPercentage = get(study, 'progress_percentage');

          return (
            <StudyItem
              key={key}
              no={key + 1}
              title={title}
              hashId={hashId}
              category={category}
              progress={progressPercentage}
              blockedReason={blockedReason}
              isBlocked={isBlocked}
              onClick={onOpenStudyLayer(hashId)}
            />
          );
        })}
      </div>
      <Layer id="study" value="study" closeButton>
        <StudyPage />
      </Layer>
    </div>
  );
});

export default Study;
