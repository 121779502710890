import React, { FormEvent, useState } from 'react';
import styles from './Reschedule.module.scss';
import { useDispatch } from 'react-redux';
import { useToast } from '../../hooks/useToast';
import { convertToKorean } from '../../cores/convertToKorean';
import { SequenceActions } from '../../store/sequence';
import { CarsActions } from '../../store/cars';
import { trim } from 'lodash';
import Ink from 'react-ink';
import CloseButton from '../../components/CloseButton/CloseButton';
import Cookies from 'js-cookie';
import { compare } from 'compare-versions';

const Reschedule: React.FC<{}> = () => {
  const [carNumber, setCarNumber] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const onChangeCarNumber = (e: FormEvent<HTMLInputElement>) => {
    const value = convertToKorean(e.currentTarget.value);
    const shouldChanged = value.length < 10;
    if (shouldChanged) {
      setCarNumber(value);
    }
  };

  const onClosePopup = () => {
    setCarNumber('');
  };

  const onClickReschedule = () => {
    if (trim(carNumber)) {
      dispatch(
        SequenceActions.createSequence([
          CarsActions.setReschedule(carNumber),
          () => {
            toast.success('재매입 신고 완료!\n거래예정 차량으로 다시 추가되었습니다.');
            onClosePopup();
          },
        ])
      );
    } else {
      toast.error('7~9자리의 차량번호를 입력해주세요.');
    }
  };

  const getTrimmedAppVersion = (appVersion: string) => /\d+\.\d+\.\d+/.exec(appVersion)?.[0] || '';
  const isCloseButtonHidden =
    Cookies.get('App-Os') === 'ios' && compare(getTrimmedAppVersion(Cookies.get('App-Version') ?? ''), '3.9.0', '<');

  return (
    <div className={styles.reschedule}>
      {!isCloseButtonHidden && <CloseButton />}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            거래취소 차량
            <br />
            재매입 신고
          </div>
          <input className={styles.carNumberInput} value={carNumber} onChange={onChangeCarNumber} placeholder="12가1234" />
          <div className={styles.description}>
            <div className={styles.subTitle}>재매입 신고 안내사항</div>
            <div className={styles.content}>
              * 거래취소 후 다시 고객이 연락이 와서 매입하셨다면 신고해주세요.
              <br />* 차량은 다시 거래예정 상태로 변경됩니다.
            </div>
          </div>
        </div>
        <button className={styles.button} onClick={onClickReschedule}>
          입력완료
          <Ink />
        </button>
      </div>
    </div>
  );
};

export default Reschedule;
