function getBoundingRect(element: HTMLElement) {
  const r = { x: element.offsetLeft, y: element.offsetTop };

  if (element.offsetParent instanceof HTMLElement) {
    const tmp = getBoundingRect(element.offsetParent);
    r.x += tmp.x;
    r.y += tmp.y;
  }

  return r;
}

export function getPosition(e: HTMLElement): { x: number; y: number } | null {
  if (e === null || !(e instanceof HTMLElement)) {
    return null;
  }

  const boundingRect = getBoundingRect(e);
  return { x: boundingRect.x, y: boundingRect.y };
}
