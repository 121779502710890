import { get, map } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './RecentReviews.module.scss';
import { useDispatch } from 'react-redux';
import { UserActions, UserTypes } from '../../store/user';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import RecentReviewsItem from '../RecentReviewsItem/RecentReviewsItem';
import classNames from 'classnames';
import Loading from '../Loading/Loading';
import { usePagination } from '../../hooks/usePagination';

interface Props {}

const RecentReviews: FC<Props> = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { isStop, isLoading, reviews, totalReviewsCount } = useTypedSelector(
    ({ user: { reviews, hasNextReviews, totalReviewsCount }, loading: { asyncMap } }) => {
      return {
        isStop: !hasNextReviews,
        isLoading: get(asyncMap, UserTypes.getUserReviews) > 0,
        reviews,
        totalReviewsCount
      };
    }
  );

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserReviews(hashId, page));
  }, [hashId, page]);

  const isZeroReviews = totalReviewsCount === 0;

  const onNextPage = useCallback(() => {
    if (isLoading || isStop) {
      return;
    }

    setPage(prevPage => prevPage + 1);
  }, [isLoading, isStop]);

  usePagination("review-layer", onNextPage);

  return (
    <div className={classNames(styles.recentReviews, isZeroReviews && styles.isZeroReviews)}>
      {isZeroReviews ? (
        <div className={styles.zeroReviews}>
          <h1 className={styles.title}>거래후기가 없습니다</h1>
        </div>
      ) : (
        <div className={styles.inner}>
          <h1 className={styles.title}>최근 거래순</h1>
          <div className={styles.items}>
            {map(reviews, review => {
              const hashId = get(review, 'hash_id');
              return <RecentReviewsItem key={hashId} data={review} />;
            })}
          </div>
          {isLoading && (
            <div className={styles.loading}>
              <Loading />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecentReviews;
