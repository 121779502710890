import React, { FC, memo, Suspense } from 'react';
import styles from './App.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Route, Switch } from 'react-router';
import Profile from '../Profile/Profile';
import Study from '../Study/Study';
import Tracking from '../Tracking/Tracking';
import OfficeInfoUpdate from '../OfficeInfoUpdate/OfficeInfoUpdate';
import Reschedule from '../../pages/Reschedule/Reschedule';
import Loader from '../membership/Loader/Loader';
import { withHeydealerTheme } from '@PRNDcompany/emotion-theme';
import ProfileDetailPage from 'src/pages/ProfileDetailPage';
import WarningsPage from 'src/pages/WarningsPage';
import MonthlyTradesPage from 'src/pages/MonthlyTradesPage';
import ReviewPage from 'src/pages/ReviewPage';
import SuccessRatePage from 'src/pages/SuccessRatePage';

import Cars from '../Cars/Cars';
import SatisfactionPage from 'src/pages/SatisfactionPage';

const MembershipGuide = React.lazy(() => import('./../../pages/MembershipGuide/MembershipGuide'));
const MembershipMission = React.lazy(() => import('../../pages/MembershipMission/MembershipMission'));

interface Props {}

const App: FC<Props> = memo(() => {
  const { token } = useTypedSelector(({ user: { token } }) => ({ token }));
  const isAuthenticated = token !== null;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.app}>
        <Switch>
          <Route path="/profile/:hashId/detail" component={ProfileDetailPage} />
          <Route path="/profile/:hashId/success-rate" component={SuccessRatePage} />
          <Route path="/profile/:hashId/warnings" component={WarningsPage} />
          <Route path="/profile/:hashId/monthly-trades" component={MonthlyTradesPage} />
          <Route path="/profile/:hashId/review" component={ReviewPage} />
          <Route path="/profile/:hashId/cars" component={Cars} />
          <Route path="/profile/:hashId/satisfaction" component={SatisfactionPage} />
          <Route path="/profile/:hashId" component={Profile} />
          <Route path="/study/:hashId" component={Study} />
          <Route path="/tracking/report/:trackingId" component={Tracking} />
          <Route path="/office_info_update/:hashId" component={OfficeInfoUpdate} />
          <Route path="/reschedule" component={Reschedule} />
          {/*준회원 제도 가이드*/}
          <Route path="/membership_guide" component={MembershipGuide} />
          {/*준회원 남은 미션*/}
          <Route path="/membership_mission" component={MembershipMission} />
        </Switch>
      </div>
    </Suspense>
  );
});

export default withHeydealerTheme(App);
