import React, { FC, memo } from 'react';
import styles from './RecentReviewTradeResult.module.scss';
import { map, get, size, chain } from 'lodash';
import { toLocaleString } from '../../../cores/toLocaleString';
import classNames from 'classnames';
import InspectionImageLoader from '../../InspectionImageLoader/InspectionImageLoader';
import { useInView } from 'react-intersection-observer';

interface Props {
  abTest: any;
  carNumber: string;
  tradeResult: any;
  selectedBidPrice: number;
}

const RecentReviewTradeResult: FC<Props> = memo(({ abTest, carNumber, tradeResult, selectedBidPrice }) => {
  const [ref, inView] = useInView({
    threshold: 0.5
  });

  const reductionReview = get(tradeResult, 'reduction_review');
  const status = get(reductionReview, 'status');

  const isStatusOngoing = status === 'ongoing';

  const inspectionResult = get(tradeResult, 'inspection_result');
  const inspectionImages = get(tradeResult, 'inspection_images');
  const reductions = get(tradeResult, 'reductions_for_review');
  const tradedPrice = get(tradeResult, 'traded_price');

  const noReduction = tradedPrice === selectedBidPrice;

  const hasReductions = size(reductions) > 0;
  const hasInspectionImages = size(inspectionImages) > 0;

  const reduction = get(abTest, 'reduction');

  const diffPrice = tradedPrice - selectedBidPrice;

  return (
    <div className={styles.recentReviewTradeResult}>
      <h1 className={styles.title}>최종 거래결과</h1>
      <div className={styles.content}>
        {isStatusOngoing ? (
          <div className={styles.normalText}>{carNumber} 차량 거래내용을 헤이딜러 감가심사센터에서 꼼꼼히 검토중입니다.</div>
        ) : noReduction ? (
          <div className={styles.normalText}>기재해주신 내용과 동일하여, 감가없이 거래되었습니다.</div>
        ) : (
          <React.Fragment>
            {hasInspectionImages && (
              <div className={styles.inspectionImages} ref={ref}>
                {map(inspectionImages, (image, key: number) => {
                  const id = get(image, 'id');
                  const url = get(image, 'url');
                  return (
                    <InspectionImageLoader
                      className={styles.inspectionImage}
                      key={id}
                      inView={inView}
                      style={{ marginRight: (key + 1) % 5 === 0 ? 0 : 7 }}
                      url={`http://image.heydealer.com/unsafe/300x300/${url}`}
                    />
                  );
                })}
              </div>
            )}
            {hasReductions ? (
              <React.Fragment>
                <div className={styles.reductions}>
                  {map(reductions, (reduction, key) => {
                    const content = get(reduction, 'content');
                    const price = get(reduction, 'price');
                    return (
                      <div key={key} className={styles.reduction}>
                        <div className={styles.text} dangerouslySetInnerHTML={{ __html: content }} />
                        {typeof price === 'number' && <div className={styles.price}>-{toLocaleString(price)}만원</div>}
                      </div>
                    );
                  })}
                </div>
                {reduction === 'a' && (
                  <div className={styles.normalReductionPrice}>
                    <span>{diffPrice > 0 ? '감가없음' : `${toLocaleString(diffPrice)}만원`}</span>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className={classNames(styles.normalText, styles.old)}>
                <div className={styles.normalReductions}>
                  {chain(inspectionResult)
                    .split('\n')
                    .map((i, key) => {
                      return (
                        <div key={key} className={styles.normalReduction}>
                          <span>·</span>
                          <span>{i}</span>
                        </div>
                      );
                    })
                    .value()}
                </div>
                <div className={styles.normalReductionPrice}>
                  <span>{diffPrice >= 0 ? '감가없음' : `${toLocaleString(diffPrice)}만원`}</span>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default RecentReviewTradeResult;
