import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './MonthlyTrades.module.scss';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, map, slice, size } from 'lodash';
import { UserActions, UserTypes } from '../../store/user';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import Loading from '../Loading/Loading';
import Typography from '@PRNDcompany/typography';
import colors from '@PRNDcompany/colors';
import { useLocation } from 'react-router';

interface Props {}

const MonthlyTrades: FC<Props> = () => {
  const { hashId } = useParams<{ hashId: string }>();
  const dispatch = useDispatch();
  const [isDetail, setDetail] = useState(false);

  const { isLoadedMonthlyTrades, monthlyTrades } = useTypedSelector(
    ({ user: { monthlyTrades, isLoadedMonthlyTrades }, loading: { asyncMap } }) => {
      return {
        isLoadedMonthlyTrades,
        monthlyTrades,
      };
    }
  );

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(UserActions.getUserMonthlyTrades(hashId));
  }, [hashId]);

  const isZeroMonthlyTrades = size(monthlyTrades) === 0 && isLoadedMonthlyTrades;
  const isGraterThanFor = size(monthlyTrades) > 4;

  const renderContent = () => {
    if (isZeroMonthlyTrades) {
      return (
        <div className={styles.inner}>
          <div className={styles.body}>
            <h2 className={styles.empty}>거래 내역이 없습니다</h2>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.inner}>
        <div className={classNames(styles.body, isDetail && styles.isDetail)}>
          {map(isDetail ? monthlyTrades : slice(monthlyTrades, 0, 4), (monthlyTrade) => {
            const date = DateTime.fromFormat(get(monthlyTrade, 'month'), 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy.MM');
            return (
              <div key={monthlyTrade.month} className={styles.monthlyTrade}>
                <Typography variant="Body_1" color={colors.$base_gray6}>
                  {date}
                </Typography>
                <Typography variant="Body_1">
                  일반 {monthlyTrade.basic_count}대 ㆍ제로 {monthlyTrade.zero_count}대
                </Typography>
              </div>
            );
          })}
        </div>
        {isGraterThanFor && !isDetail && (
          <button
            className={styles.detail}
            onClick={() => {
              setDetail(true);
            }}
          >
            더보기 +
          </button>
        )}
      </div>
    );
  };
  return <div className={styles.monthlyTrades}>{renderContent()}</div>;
};

export default MonthlyTrades;
