import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { action, PayloadAction } from 'typesafe-actions';
import { Cars } from '../declaration/cars';
import { Envelope } from '../declaration';
import { get, uniqBy } from 'lodash';

export enum CarsTypes {
  getCars = '@cars/getCars',
  truncateCars = '@cars/truncateCars',
  getPreviewTradedCars = '@cars/getPreviewTradedCars',
  getPreviewFailedCars = '@cars/getPreviewFailedCars',

  setReschedule = '@cars/setReschedule',
}

export interface CarsState {
  cars: Array<Cars>;
  hasNextCars: boolean;
  totalCarsCount: number | null;

  previewTradedCars: Array<Cars>;
  previewFailedCars: Array<Cars>;
  previewTradedCarsCount: number | null;
  previewFailedCarsCount: number | null;
  isLoadedTradedCars: boolean;
  isLoadedFailedCars: boolean;
}

export const CarsActions = {
  truncateCars: () => action(CarsTypes.truncateCars),
  getCars: createAsyncSagaAction(CarsTypes.getCars, (query: URLSearchParams) => {
    query.set('envelope', 'true');
    return axios.get(`/cars/`, { params: query });
  }),
  getPreviewTradedCars: createAsyncSagaAction(CarsTypes.getPreviewTradedCars, () => {
    const query = new URLSearchParams();
    query.set('page', '1');
    query.set('type', 'traded');
    query.set('envelope', 'true');
    query.set('page_size', '4');
    query.set('is_action_required', 'false');

    return axios.get(`/cars/`, { params: query });
  }),
  getPreviewFailedCars: createAsyncSagaAction(CarsTypes.getPreviewFailedCars, () => {
    const query = new URLSearchParams();
    query.set('page', '1');
    query.set('type', 'failed');
    query.set('envelope', 'true');
    query.set('page_size', '4');
    query.set('is_action_required', 'false');

    return axios.get(`/cars/`, { params: query });
  }),
  setReschedule: createAsyncSagaAction(CarsTypes.setReschedule, (carNumber: string) =>
    axios.post(`/reschedule/`, { car_number: carNumber })
  ),
};

const initialState: CarsState = {
  cars: [],
  hasNextCars: false,
  totalCarsCount: null,

  previewTradedCars: [],
  previewFailedCars: [],
  previewTradedCarsCount: null,
  previewFailedCarsCount: null,
  isLoadedTradedCars: false,
  isLoadedFailedCars: false,
};

export default handleActions<CarsState, any>(
  {
    [CarsTypes.truncateCars]: (state) => {
      return produce(state, (draft) => {
        draft.cars = [];
        draft.hasNextCars = false;
        draft.totalCarsCount = null;
      });
    },
    ...createAsyncSagaReducerMap(CarsTypes.getCars, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<Envelope<Cars>>>) => {
        return produce(state, (draft) => {
          const { meta, results } = action.payload.data;
          const count = get(meta, 'count');
          const next = get(meta, 'next');
          draft.cars = uniqBy([...state.cars, ...results], 'hash_id');
          draft.hasNextCars = next !== null;
          draft.totalCarsCount = count;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getPreviewTradedCars, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<Envelope<Cars>>>) => {
        return produce(state, (draft) => {
          draft.previewTradedCars = action.payload.data.results;
          draft.previewTradedCarsCount = action.payload.data.meta.count;
          draft.isLoadedTradedCars = true;
        });
      },
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getPreviewFailedCars, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<Envelope<Cars>>>) => {
        return produce(state, (draft) => {
          draft.previewFailedCars = action.payload.data.results;
          draft.previewFailedCarsCount = action.payload.data.meta.count;
          draft.isLoadedFailedCars = true;
        });
      },
    }),
  },
  initialState
);
