import { map, get, size } from 'lodash';
import React, { FC } from 'react';
import styles from './PreviewCarsGroup.module.scss';
import { Cars } from '../../declaration/cars';
import { getResizeUrl } from '../../cores/getResizeUrl';
import NumberEasing from '../NumberEasing/NumberEasing';

interface Props {
  title: string;
  data: Array<Cars>;
  count: number | null;
  onClick: () => void;
}

const PreviewCarsGroup: FC<Props> = ({ title, data, count, onClick }) => {
  const width = window.innerWidth - 40;
  const volume = width * 0.225;

  return (
    <div className={styles.previewCarsGroup}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.thumbnails} style={{ height: volume }}>
        {map(data, (car, key) => {
          const hashId = get(car, 'hash_id');
          const detail = get(car, 'detail');
          const mainImageUrl = get(detail, 'main_image_url');

          return (
            <div
              style={{ width: volume, height: volume, background: `url(${getResizeUrl(200, 200, mainImageUrl)})` }}
              className={styles.thumbnail}
              key={hashId}
            />
          );
        })}
      </div>
      <button className={styles.more} onClick={onClick}>
        <NumberEasing value={count} />대 모두 보기
      </button>
    </div>
  );
};

export default PreviewCarsGroup;
