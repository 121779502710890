import React, { CSSProperties, FC, memo, useEffect, useState } from 'react';
import styles from './InspectionImageLoader.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
  inView: boolean;
  style?: CSSProperties;
  url: string;
  type?: string;
}

const InspectionImageLoader: FC<Props> = memo(({ className, type, inView, style = {}, url }) => {
  const [isLoad, setLoad] = useState(false);

  useEffect(() => {
    if (!inView) {
      return;
    }

    setLoad(false);

    const onLoad = () => {
      setLoad(true);
    };

    const image = new Image();
    image.src = url;
    image.addEventListener('load', onLoad);

    return () => {
      image.removeEventListener('load', onLoad);
    };
  }, [url, inView]);

  return (
    <div style={{ ...style }} className={classNames(styles.inspectionImageLoader, type ? styles[type] : null, className)}>
      <div className={classNames(styles.preview, !isLoad && styles.isVisible)} />
      <div
        style={{ background: isLoad ? `url('${url}')` : '' }}
        className={classNames(styles.image, isLoad && styles.isVisible)}
      />
    </div>
  );
});

export default InspectionImageLoader;
