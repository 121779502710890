const initialConsonant = 'rRseEfaqQtTdwWczxvg';
const initialConsonantRegExp = `[${initialConsonant}]`;
const medialConsonant: { [key: string]: number } = {
  k: 0,
  o: 1,
  i: 2,
  O: 3,
  j: 4,
  p: 5,
  u: 6,
  P: 7,
  h: 8,
  hk: 9,
  ho: 10,
  hl: 11,
  y: 12,
  n: 13,
  nj: 14,
  np: 15,
  nl: 16,
  b: 17,
  m: 18,
  ml: 19,
  l: 20
};

const medialConsonantRegExp = 'hk|ho|hl|nj|np|nl|ml|k|o|i|O|j|p|u|P|h|y|n|b|m|l';

const finalConsonant: { [key: string]: number } = {
  '': 0,
  r: 1,
  R: 2,
  rt: 3,
  s: 4,
  sw: 5,
  sg: 6,
  e: 7,
  f: 8,
  fr: 9,
  fa: 10,
  fq: 11,
  ft: 12,
  fx: 13,
  fv: 14,
  fg: 15,
  a: 16,
  q: 17,
  qt: 18,
  t: 19,
  T: 20,
  d: 21,
  w: 22,
  c: 23,
  z: 24,
  x: 25,
  v: 26,
  g: 27
};

const finalConsonantRegExp = 'rt|sw|sg|fr|fa|fq|ft|fx|fv|fg|qt|r|R|s|e|f|a|q|t|T|d|w|c|z|x|v|g|';

const convertRegExp = new RegExp(
  `(${initialConsonantRegExp})(${medialConsonantRegExp})((${finalConsonantRegExp})(?=(${initialConsonantRegExp})(${medialConsonantRegExp}))|(${finalConsonantRegExp}))`,
  'g'
);

function convert(value: string, initial: any, medial: any, final: any) {
  return String.fromCharCode(
    initialConsonant.indexOf(initial) * 588 + medialConsonant[medial] * 28 + finalConsonant[final] + 44032
  );
}

export function convertToKorean(value: string) {
  return value.replace(convertRegExp, convert);
}
