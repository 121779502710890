import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Cars.module.scss';
import LayerTitle from '../LayerTitle/LayerTitle';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, map } from 'lodash';
import { CarsActions, CarsTypes } from '../../store/cars';
import NumberEasing from '../NumberEasing/NumberEasing';
import CarsItem from '../CarsItem/CarsItem';
import { usePagination } from '../../hooks/usePagination';
import Loading from '../Loading/Loading';

interface Props {}

const Cars: FC<Props> = () => {
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const type = query.get('type');
  const title = type === 'traded' ? '거래완료' : type === 'failed' ? '거래실패' : null;

  const { isStop, isLoading, cars, totalCarsCount } = useTypedSelector(
    ({ cars: { cars, hasNextCars, totalCarsCount }, loading: { asyncMap } }) => {
      return {
        isStop: !hasNextCars,
        isLoading: get(asyncMap, CarsTypes.getCars) > 0,
        cars,
        totalCarsCount
      };
    }
  );

  useEffect(() => {
    if (type === null) {
      return;
    }

    const query = new URLSearchParams();
    query.set('page', page.toString());
    query.set('type', type);
    dispatch(CarsActions.getCars(query));
  }, [dispatch, type, page]);

  useEffect(() => {
    dispatch(CarsActions.truncateCars())
  }, [search]);

  const onNextPage = useCallback(() => {
    if (isLoading || isStop) {
      return;
    }

    setPage(prevPage => prevPage + 1);
  }, [isLoading, isStop]);

  usePagination("cars-layer", onNextPage);

  if (!title) {
    return null;
  }

  return (
    <div className={styles.cars}>
      <LayerTitle>
        {title}
        {typeof totalCarsCount === 'number' && (
          <span className={styles.count}>
            <NumberEasing value={totalCarsCount} />
          </span>
        )}
      </LayerTitle>
      <div className={styles.inner}>
        {map(cars, (car) => {
          return (
            <CarsItem data={car} key={get(car, 'hash_id')} />
          )
        })}
      </div>
      {isLoading && <div className={styles.loading}><Loading /></div>}
    </div>
  );
};

export default Cars;
