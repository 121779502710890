import React, { FC, memo } from 'react';
import styles from './Emoji.module.scss';
import classNames from 'classnames';
import { get } from 'lodash';

interface Props {
  className?: string;
  name: 'index-finger-pointing-up';
  size?: number;
}

const Emoji: FC<Props> = memo(({ size = 32, name, className }) => {
  return (
    <div className={classNames(styles.emoji, get(styles, name), className)} style={{ width: size, height: size }} />
  );
});

export default Emoji;
