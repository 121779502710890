import { get, map } from 'lodash';
import React, { FC, useMemo } from 'react';
import styles from './SatisfactionPage.module.scss';
import LayerTitle from '../LayerTitle/LayerTitle';
import { FailedReview } from '../../declaration/profile';
import NumberEasing from '../NumberEasing/NumberEasing';
import classNames from 'classnames';
import SatisfactionChart from '../SatisfactionChart/SatisfactionChart';

interface Props {
  failedReview?: FailedReview;
}

const satisfactions = ['good', 'fair', 'poor'];
const satisfactionDisplayMap = {
  good: '만족',
  fair: '보통',
  poor: '불만족'
};

const SatisfactionPage: FC<Props> = ({ failedReview }) => {
  const count = get(failedReview, 'count');
  const countByChoice = get(failedReview, 'count_by_choice');

  const good = get(countByChoice, 'good');
  const fair = get(countByChoice, 'fair');
  const poor = get(countByChoice, 'poor');

  const satisfactionValues = useMemo(() => {
    return [
      {
        value: good,
        color: '#396eff'
      },
      {
        value: fair,
        color: '#ccd8e8'
      },
      {
        value: poor,
        color: '#ed7583'
      }
    ];
  }, [good, fair, poor]);

  return (
    <div className={styles.satisfactionPage}>
      <LayerTitle>상담 만족도</LayerTitle>
      <div className={styles.header}>
        <p className={styles.total}>
          누적 만족도 평가 : <NumberEasing value={count} />개
        </p>
      </div>
      <div className={styles.body}>
        <div className={styles.satisfactions}>
          {map(satisfactions, satisfaction => {
            const value = get(countByChoice, satisfaction);
            const label = get(satisfactionDisplayMap, satisfaction);

            return (
              <div className={classNames(styles.satisfaction, get(styles, satisfaction))} key={satisfaction}>
                <span className={styles.shape} />
                <span className={styles.label}>{label}</span>
                <span className={styles.count}>{typeof value === 'number' ? <NumberEasing value={value} /> : '-'}</span>
                <span className={styles.percent}>
                  (
                  {typeof value === 'number' && typeof count === 'number' ? (
                    <NumberEasing value={(value / count) * 100} fractionDigits={1} />
                  ) : (
                    '-'
                  )}
                  %)
                </span>
              </div>
            );
          })}
        </div>
        <SatisfactionChart values={satisfactionValues} />
      </div>
      <div className={styles.description}>
        <p>
          <span>*</span>
          <span>거래 취소고객은 상담 만족도 평가 가능 (월 1회 업데이트)</span>
        </p>
        <p>
          <span>*</span>
          <span>
            만족도 평가 내용은 심사팀에서 모니터링 하며, 개별 피드백 드릴 수 있습니다.
          </span>
        </p>
        <p>
          <span>*</span>
          <span>
            회원딜러 분도 거래상세 페이지에서 고객 평가가 가능합니다.
          </span>
        </p>
      </div>
    </div>
  );
};

export default SatisfactionPage;
