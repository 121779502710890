import React, { FC, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { toLocaleString } from '../../cores/toLocaleString';

interface Props {
  value?: number | null;
  fractionDigits?: number;
}

const NumberEasing: FC<Props> = ({ value, fractionDigits = 0 }) => {
  const [val, setValue] = useState(0);
  const props = useSpring({ value: val });

  useEffect(() => {
    setValue(value || 0);
  }, [value]);

  if (value === null || value === undefined || (typeof value === 'number' && isNaN(value))) {
    return <animated.span>-</animated.span>;
  }

  return <animated.span>{props.value.interpolate((x: any) => toLocaleString(x.toFixed(fractionDigits)))}</animated.span>;
};

export default NumberEasing;
