import React, { FC, useEffect, useRef } from 'react';
import styles from './SuccessRateChart.module.scss';
import { useDispatch } from 'react-redux';
import { easeCubicInOut } from 'd3-ease';

interface Props {
  value?: number;
}

const width = 104 * 2;
const height = 104 * 2;
const halfWidth = width * 0.5;
const halfHeight = height * 0.5;

const PI = Math.PI;
const PIHalf = PI * 0.5;
const PI2 = PI * 2;

const SuccessRateChart: FC<Props> = ({ value }) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let cancelRequestAnimationId: number | null = null;
    let startedAt = Date.now();
    let delta = 0;
    let animation = 0;

    const percent = (value || 0) / 100;
    const context = ref.current.getContext('2d')!;

    const render = () => {
      cancelRequestAnimationId = window.requestAnimationFrame(render);

      const currentAt = Date.now();
      if (currentAt - startedAt > 0) {
        delta = (currentAt - startedAt) * 0.001;
        startedAt = currentAt;
      }

      animation += delta;

      if (animation > 1) {
        animation = 1;
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }

      const easing = easeCubicInOut(animation);

      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.strokeStyle = '#e9edf4';
      context.lineWidth = 16;
      context.arc(halfWidth, halfHeight, 96, 0, PI2);
      context.stroke();

      context.beginPath();
      context.strokeStyle = '#396eff';
      context.arc(halfWidth, halfHeight, 96, PI + PIHalf - PI2 * percent * easing, PI + PIHalf);
      context.stroke();

      context.beginPath();
      context.font = 'normal 48px "SDGothicNeo1"';
      context.textBaseline = 'top';
      context.fillStyle = typeof value === 'number' ? '#396eff' : '#414d6b';
      const text = typeof value === 'number' ? (value * easing).toFixed(0) : '-';
      const { width: textWidth } = context.measureText(text);

      context.fillText(text, halfWidth - textWidth * 0.5 - (typeof value === 'number' ? 12 : 0), 64);

      if (typeof value === 'number') {
        context.font = 'normal 24px "SDGothicNeo1"';
        context.fillText('%', halfWidth - textWidth * 0.5 + textWidth - 12, 84);
      }

      context.font = 'normal 24px "SDGothicNeo1"';
      context.fillStyle = '#414d6b';

      const { width: textWidth3 } = context.measureText('성사율');
      context.fillText('성사율', halfWidth - textWidth3 * 0.5, 124);
      context.fill();
    };

    cancelRequestAnimationId = window.requestAnimationFrame(render);

    return () => {
      if (cancelRequestAnimationId !== null) {
        window.cancelAnimationFrame(cancelRequestAnimationId);
      }
    };
  }, [value]);

  return <canvas className={styles.successRateChart} ref={ref} width={width} height={height} />;
};

export default SuccessRateChart;
