import React, { FC, memo, useEffect } from 'react';
import styles from './StudyTest.module.scss';
import { Test } from '../../declaration/study';
import { map, get, forEach, size, findIndex } from 'lodash';
import classNames from 'classnames';

interface Props {
  test: Test;
  isCompleted: boolean;
  value: Array<{ number: number; answer: Array<number> }>;
  onChange: (answers: Array<{ number: number; answer: Array<number> }>) => void;
  onComplete: (isCompleted: boolean) => void;
}

const StudyTest: FC<Props> = memo(({ test, value, onChange, onComplete, isCompleted }) => {
  const questions = get(test, 'questions');

  useEffect(() => {
    let isSuccess = true;

    forEach(questions, q => {
      const number = get(q, 'number');
      const type = get(q, 'type');
      const count = type === 'multi_select' ? 2 : 1;
      const valueIndex = findIndex(value, v => v.number === number);

      if (size(get(value, [valueIndex, 'answer'])) < count) {
        isSuccess = false;
        return false;
      }
    });

    onComplete(isSuccess);
  }, [value, questions, onComplete]);

  return (
    <div className={styles.studyTest}>
      <div className={styles.questions}>
        {map(questions, (question, key) => {
          const type = get(question, 'type');
          const questionNumber = get(question, 'number');
          const content = get(question, 'content');
          const choices = get(question, 'choices');
          const answer = get(question, 'answer');

          return (
            <div key={key} className={styles.question}>
              <h2 className={styles.title}>
                <span className={styles.no}>Q{questionNumber}.</span>
                <span dangerouslySetInnerHTML={{ __html: content }} />
              </h2>
              <div className={styles.choices}>
                {map(choices, (choice, key) => {
                  const number = get(choice, 'number');
                  const content = get(choice, 'content');
                  const isActivated = isCompleted
                    ? Array.isArray(answer) && answer.indexOf(number) !== -1
                    : findIndex(
                        value,
                        nextAnswer => nextAnswer.number === questionNumber && nextAnswer.answer.indexOf(number) !== -1
                      ) !== -1;

                  return (
                    <div
                      key={key}
                      className={classNames(styles.choice, isCompleted && styles.isDisabled, isActivated && styles.isActivated)}
                      onClick={() => {
                        if (isCompleted) {
                          return;
                        }

                        const nextAnswers = [...value];
                        const answersIndex = findIndex(nextAnswers, nextAnswer => nextAnswer.number === questionNumber);

                        if (answersIndex === -1) {
                          nextAnswers.push({ number: questionNumber, answer: [number] });
                        } else {
                          if (type === 'multi_select') {
                            const answerIndex = nextAnswers[answersIndex].answer.indexOf(number);
                            if (answerIndex === -1) {
                              nextAnswers[answersIndex].answer.push(number);
                            } else {
                              nextAnswers[answersIndex].answer.splice(answerIndex, 1);
                            }
                          } else {
                            nextAnswers[answersIndex].answer = [number];
                          }
                        }

                        onChange(nextAnswers);
                      }}
                    >
                      <span className={styles.shape} />
                      <span className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default StudyTest;
